import { useState, useCallback, useRef } from "react";
import { LogLevels, Track, Room, LocalAudioTrack, LocalVideoTrack } from "twilio-video";
import { ErrorCallback } from "../../../types";

interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
  logLevel: LogLevels;
}

export default function useScreenShareToggle(room: Room | null, onError: ErrorCallback) {
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef<() => void>();
  const stopAudioShareRef = useRef<() => void>();

  const shareScreen = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: true,
        video: true
      })
      .then((stream) => {
        const videoTrack = stream.getVideoTracks()[0];
        const audioTrack = stream.getAudioTracks()[0];

        const localVideoTrack = new LocalVideoTrack(videoTrack, { name: "screenshare" });

        // All video tracks are published with 'low' priority. This works because the video
        // track that is displayed in the 'MainParticipant' component will have it's priority
        // set to 'high' via track.setPriority()
        room!.localParticipant
          .publishTrack(localVideoTrack, {
            priority: "low" // Priority is set to high by the subscriber when the video track is rendered
          } as MediaStreamTrackPublishOptions)
          .then((trackPublication) => {
            stopScreenShareRef.current = () => {
              room!.localParticipant.unpublishTrack(localVideoTrack);
              // TODO: remove this if the SDK is updated to emit this event
              room!.localParticipant.emit("trackUnpublished", trackPublication);
              videoTrack.stop();
              setIsSharing(false);
            };

            videoTrack.onended = stopScreenShareRef.current;
            setIsSharing(true);
          })
          .catch(onError);

        if (audioTrack) {
          const localAudioTrack = new LocalAudioTrack(audioTrack, { name: "audioshare" });

          room!.localParticipant
            .publishTrack(localAudioTrack, {
              priority: "low"
            } as MediaStreamTrackPublishOptions)
            .then((trackPublication) => {
              stopAudioShareRef.current = () => {
                room!.localParticipant.unpublishTrack(localAudioTrack);
                // TODO: remove this if the SDK is updated to emit this event
                room!.localParticipant.emit("trackUnpublished", trackPublication);
                audioTrack.stop();
                setIsSharing(false);
              };

              audioTrack.onended = stopAudioShareRef.current;
              setIsSharing(true);
            })
            .catch(onError);
        }
      })
      .catch((error) => {
        // Don't display an error if the user closes the screen share dialog
        if (
          error.message === "Permission denied by system" ||
          (error.name !== "AbortError" && error.name !== "NotAllowedError")
        ) {
          console.error(error);
          onError(error);
        }
      });
  }, [room, onError]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      if (isSharing) {
        stopScreenShareRef.current?.();
        stopAudioShareRef.current?.();
      } else {
        shareScreen();
      }
    }
  }, [isSharing, shareScreen, room]);

  return [isSharing, toggleScreenShare] as const;
}
