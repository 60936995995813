import * as ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";
import App from "./App";

const SENTRY_HOST = process.env.REACT_APP_SENTRY_HOST;

const sentryBrowserTracing = new Integrations.BrowserTracing();
const sentryCaptureConsole = new CaptureConsole({ levels: ["error"] });

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: SENTRY_HOST,
    integrations: [sentryBrowserTracing, sentryCaptureConsole],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    ignoreErrors: ["ResizeObserver loop limit exceeded"]
  });
}

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container!);
root.render(<App />);
