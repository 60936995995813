import { Checkbox, FormControl, FormControlLabel, MenuItem, Select, createStyles, makeStyles } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { FormError, Practitioner, Provider, ServiceDeliveredOptions } from "./helpers";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: "#fff",
      border: "1px solid #dadbde",
      borderRadius: "4px",
      padding: "14px 20px",
      display: "flex",
      alignItems: "center",
      gap: "40px"
    },
    wrapper: {
      display: "flex",
      alignItems: "flex-end",
      gap: "4px"
    },
    select: {
      "&:before": {
        borderColor: "#B5B8BD"
      },
      "&:after": {
        borderColor: "#B5B8BD",
        borderBottomWidth: "1px"
      },
      "& .MuiSelect-select": {
        color: "#414449",
        fontSize: "1rem",
        padding: "4px 0"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent"
      },
      "&.error": {
        borderColor: "#d54141"
      },
      "& .MuiSelect-icon": {
        position: "relative"
      }
    },
    selectLabel: {
      fontSize: "0.75rem",
      fontWeight: 600,
      lineHeight: "1.125rem",
      color: "#61666E"
    },
    iconWrapper: {
      display: "flex",
      color: "#4BC27D"
    },
    offIconWrapper: {
      display: "flex",
      color: "#d54141"
    },
    valueText: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
      color: "#414449"
    },
    deliveredByWrapper: {
      display: "flex",
      flexDirection: "column"
    },
    labelWrapper: {
      paddingRight: "8px"
    }
  })
);

interface AppointmentMoreDetailsProps {
  providers: Provider[];
  selectedServiceDelivered: ServiceDeliveredOptions;
  onSelectServiceDelivered: (serviceDelivered: ServiceDeliveredOptions) => void;
  selectedProviderNo: Provider["providerNumber"];
  onSelectProviderNo: (providerNo: Provider["providerNumber"]) => void;
  waiveCancellationFee: boolean;
  onSelectWaiveCancellationFee: (waiveCancellationFee: boolean) => void;
  formError: FormError;
  selectedPractitioner: Practitioner;
}

const SERVICE_DELIVERED_OPTIONS = [
  { label: "Yes - Attended", value: ServiceDeliveredOptions.Attended },
  { label: "No - Client DNA", value: ServiceDeliveredOptions.ClientDidNotAttend },
  { label: "No - Cancel Short Notice", value: ServiceDeliveredOptions.CancelShortNotice }
];

const AppointmentMoreDetails = ({
  providers,
  selectedServiceDelivered,
  onSelectServiceDelivered,
  selectedProviderNo,
  onSelectProviderNo,
  waiveCancellationFee,
  onSelectWaiveCancellationFee,
  formError,
  selectedPractitioner
}: AppointmentMoreDetailsProps) => {
  const classes = useStyles();
  const providerNumberOptions = providers.map(({ providerNumber }) => ({
    label: providerNumber,
    value: providerNumber
  }));

  const isServiceDelivered = selectedServiceDelivered === ServiceDeliveredOptions.Attended;

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <FormControl fullWidth>
          <span className={classes.selectLabel}>Service Delivered?</span>
          <Select
            onChange={(e) => onSelectServiceDelivered(e.target.value as ServiceDeliveredOptions)}
            value={selectedServiceDelivered || ""}
            variant="standard"
            className={classes.select}
            error={formError.selectedServiceDelivered}
          >
            {SERVICE_DELIVERED_OPTIONS.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                <div className={classes.labelWrapper}>{option.label}</div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isServiceDelivered ? (
          <div className={classes.iconWrapper}>
            <CheckCircleIcon color="inherit" fontSize="small" />
          </div>
        ) : (
          <div className={classes.offIconWrapper}>
            <CancelIcon color="inherit" fontSize="small" />
          </div>
        )}
      </div>
      {isServiceDelivered ? (
        <>
          <div className={classes.deliveredByWrapper}>
            <span className={classes.selectLabel}>Delivered by</span>
            <span className={classes.valueText}>{selectedPractitioner.name}</span>
          </div>
          {providerNumberOptions.length > 0 && (
            <div className={classes.wrapper}>
              <FormControl fullWidth>
                <span className={classes.selectLabel}>Provider #</span>
                {providerNumberOptions.length < 2 ? (
                  <span className={classes.valueText}>{selectedProviderNo}</span>
                ) : (
                  <Select
                    onChange={(e) => onSelectProviderNo(e.target.value as Provider["providerNumber"])}
                    value={selectedProviderNo || ""}
                    variant="standard"
                    className={classes.select}
                  >
                    {providerNumberOptions.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        <div className={classes.labelWrapper}>{option.label}</div>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </div>
          )}
        </>
      ) : (
        <FormControlLabel
          control={
            <Checkbox
              checked={waiveCancellationFee}
              onChange={(e) => onSelectWaiveCancellationFee(e.target.checked)}
              name="waiveCancellationFee"
              color="primary"
            />
          }
          label="Waive cancellation fee"
        />
      )}
    </div>
  );
};

export default AppointmentMoreDetails;
