import Avatar from "@material-ui/core/Avatar";
import makeStyles from "@material-ui/styles/makeStyles";
import Person from "@material-ui/icons/Person";
import { User } from "@auth0/auth0-react";

const useStyles = makeStyles({
  red: {
    color: "white",
    backgroundColor: "#F22F46"
  }
});

export function getInitials(name: string) {
  return name
    .split(" ")
    .map((text) => text[0])
    .join("")
    .toUpperCase();
}

export default function UserAvatar({ user }: { user: User }) {
  const classes = useStyles();
  const { name, picture } = user;

  return picture ? (
    <Avatar src={picture} />
  ) : (
    <Avatar className={classes.red}>{name ? getInitials(name) : <Person />}</Avatar>
  );
}
