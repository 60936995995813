import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from "@material-ui/core";

interface AlertDialogProps {
  title: string;
  desc: string;
  open: boolean;
  onCancel: () => void;
  onOK: () => void;
}

const AlertDialog = ({ title, desc, open, onCancel, onOK }: AlertDialogProps) => {
  return (
    <Dialog open={open} onClose={() => onCancel()} fullWidth={true} maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{desc}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => onCancel()} color="inherit" variant="contained">
          Cancel
        </Button>
        <Button onClick={() => onOK()} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
