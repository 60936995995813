/* eslint-disable react/no-multi-comp */
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader/Loader";

const Logout = () => {
  const { isAuthenticated, isLoading, logout } = useAuth0();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      } else {
        window.location.href = window.location.origin;
      }
    }
  }, [isAuthenticated, isLoading, logout]);

  return <Loader />;
};

export default Logout;
