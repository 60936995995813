import axios from "axios";

const billingServiceApi = axios.create({
  baseURL: process.env.REACT_APP_BILLING_SERVICE_URL!,
  headers: {
    "Content-Type": "application/json"
  },
  validateStatus: (status) => status < 400
});

export const getClinicianProviders = async (token: string, clinicianId: string) => {
  return billingServiceApi.get(`workspaces/clinicians/${clinicianId}/claimingcom/providers`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
