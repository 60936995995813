import { useCallback, useEffect, useState } from "react";
import { UserProfile, getClinicianProfileById } from "../services/clinicianProfileService";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppState } from "../state";

export const useFetchClinicianProfileById = (clinicianId?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { setAppointmentClinician } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clinician, setClinicianProfile] = useState<UserProfile>();

  const fetchClinicianProfile = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    try {
      const clinicianProfileResponse = await getClinicianProfileById(token, clinicianId || "");
      setClinicianProfile(clinicianProfileResponse.data);
      setAppointmentClinician(clinicianProfileResponse.data);
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsLoading(false);
    }
  }, [clinicianId, getAccessTokenSilently, setAppointmentClinician]);

  useEffect(() => {
    if (clinicianId) {
      fetchClinicianProfile();
    }
  }, [clinicianId, fetchClinicianProfile]);

  return {
    isLoading,
    clinician
  };
};
