import { Button, Chip, createStyles, makeStyles } from "@material-ui/core";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import FlashOffIcon from "@mui/icons-material/FlashOff";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ClaimType } from "./helpers";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      gap: "16px"
    },
    button: {
      border: "1px solid #3F52FF",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
      color: "#3F52FF",
      padding: "12px"
    },
    chip: {
      "&.MuiChip-outlined": {
        border: "1px solid #4bc27d",
        backgroundColor: "#edf9f2",
        color: "#202225",
        height: "23px"
      },
      "& .MuiChip-label": {
        fontSize: "0.625rem",
        fontWeight: 600,
        lineHeight: "0.938rem",
        color: "#202225"
      }
    },
    secondaryChip: {
      "&.MuiChip-outlined": {
        border: "1px solid #DADBDE",
        backgroundColor: "#fff",
        color: "#202225",
        height: "23px"
      },
      "& .MuiChip-label": {
        fontSize: "0.625rem",
        fontWeight: 600,
        lineHeight: "0.938rem",
        color: "#202225"
      }
    }
  })
);

interface AppointmentInvoiceDetailsProps {
  selectedClaimType?: ClaimType;
}

const AppointmentInvoiceDetails = ({ selectedClaimType }: AppointmentInvoiceDetailsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        disabled
        variant="outlined"
        className={classes.button}
        startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
      >
        Create Invoice
      </Button>
      <Chip
        className={selectedClaimType ? classes.chip : classes.secondaryChip}
        label={selectedClaimType ? "Billing Automation Set" : "No Billing Automation"}
        onDelete={() => {}}
        deleteIcon={
          selectedClaimType ? (
            <FlashOnIcon color="action" fontSize="inherit" />
          ) : (
            <FlashOffIcon color="action" fontSize="inherit" />
          )
        }
        variant="outlined"
      />
    </div>
  );
};

export default AppointmentInvoiceDetails;
