import { Fragment } from "react";
import { useAppState } from "../../state";
import { styled } from "@material-ui/core";
import CollaborationViewIcon from "@material-ui/icons/AccountBox";
import { useVideoContext } from "../VideoProvider";

const IconContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: "white",
  border: "1px solid gray",
  cursor: "pointer",
  width: 35,
  height: 35
});

const RedMark = styled("div")({
  position: "absolute",
  top: "0",
  right: "0",
  height: 20,
  minWidth: 20,
  borderRadius: "50%",
  backgroundColor: "red",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white"
});

interface Props {
  style?: React.CSSProperties;
  className?: string;
}

const WaitroomControlToggleButton = ({ style, className }: Props) => {
  const { user } = useAppState();
  const { participantsInWaitroom, isAdminControlOpen, setIsAdminControlOpen } = useVideoContext();

  if (!user?.isClinician) {
    return <Fragment />;
  }

  return (
    <div
      style={{
        display: "inline-block",
        ...style
      }}
      className={className}
      onClick={() => setIsAdminControlOpen(!isAdminControlOpen)}
    >
      <IconContainer>
        <CollaborationViewIcon />
      </IconContainer>
      {participantsInWaitroom.length > 0 && <RedMark>{participantsInWaitroom.length}</RedMark>}
    </div>
  );
};

export default WaitroomControlToggleButton;
