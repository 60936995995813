// This function is used to provide error messages to the user that are
// different than the error messages provided by the SDK.
const enhanceMessage = (message = "", code?: number) => {
  switch (true) {
    case code === 20101: // Invalid token error
      return message + ". Please make sure you are using the correct credentials.";
    case message === "Permission denied by system":
      return "Unable to share your screen. Please make sure that your operating system has the correct permissions enabled for screen sharing.";
    case message === "Invalid time to join room":
      return "This room ID is not scheduled to be available for an appointment at this time. Please check you are on the right link.";
    default:
      return message;
  }
};

export default enhanceMessage;
