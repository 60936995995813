import { useEffect, useState } from "react";
import { useVideoContext } from "../../components/VideoProvider";

/**
 * "connected" does not mean that user is in the call
 * user in waitlist room is also consider "connected"
 */
type RoomStateType = "disconnected" | "connected" | "reconnecting";

export default function useRoomState() {
  const { room } = useVideoContext();
  const [state, setState] = useState<RoomStateType>("disconnected");

  useEffect(() => {
    if (room) {
      const setRoomState = () => setState(room.state as RoomStateType);
      setRoomState();
      room.on("disconnected", setRoomState).on("reconnected", setRoomState).on("reconnecting", setRoomState);
      return () => {
        room.off("disconnected", setRoomState).off("reconnected", setRoomState).off("reconnecting", setRoomState);
      };
    }
  }, [room]);

  return state;
}
