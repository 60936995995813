import { useEffect } from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { useAppState } from "../../state";
import { useNavigate, useParams } from "react-router-dom";
import TacklitSecureLogo from "./TacklitSecureLogo";
import Background from "../Background/Background";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: "1",
    display: "flex",
    width: "95vw",
    maxWidth: "1200px",
    minHeight: "379px",
    borderRadius: "8px",
    boxShadow: "4px 4px 8px 4px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      width: "calc(100% - 40px)",
      height: "fit-content",
      margin: "55px auto 20px auto",
      maxWidth: "400px"
    }
  },
  sideContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 30,
    backgroundColor: "#263054",
    backgroundSize: "cover",
    width: "286px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingTop: 0
    }
  },
  logoContainer: {
    width: "100%",
    textAlign: "center",
    padding: "0 20px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
      textAlign: "initial",
      "& svg": {
        height: "64px"
      },
      padding: "2em"
    }
  },
  tacklitLogo: {
    width: "auto",
    height: 50,
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0
    }
  },
  content: {
    background: "white",
    width: "100%",
    padding: "3em 4em",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "2em"
    }
  },
  text: {
    color: "#ffffff",
    textAlign: "left",
    margin: "0 0 20px 10px",
    [theme.breakpoints.down("sm")]: {
      margin: "12px 0 0 0",
      width: "100%"
    }
  },
  hostButton: {
    display: "flex",
    flexDirection: "column",
    margin: "0 10px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%"
    }
  },
  hostButtonText: {
    color: "white",
    textAlign: "left",
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      "&:not(:first-child)": {
        paddingLeft: "4px"
      }
    }
  }
}));

interface IntroContainerProps {
  customText?: string;
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { user } = useAppState();
  const navigate = useNavigate();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();

  useEffect(() => {
    if (URLRoomName?.endsWith(".")) {
      navigate(`/room/${URLRoomName.slice(0, -1)}`);
    }
  }, [URLRoomName, navigate]);

  const handleNavigateToClinicianPage = () => {
    return navigate(`/clinicians${URLRoomName ? `/room/${URLRoomName}` : ""}`);
  };

  return (
    <Background>
      <div className={classes.container}>
        <div className={classes.sideContainer}>
          <div className={classes.logoContainer}>
            <TacklitSecureLogo
              isDark
              imgProps={{
                className: classes.tacklitLogo
              }}
            />
            <h2 className={classes.text}>Welcome{user && " Back"}</h2>
            {props.customText ? (
              <Typography variant="body1" className={classes.text}>
                {props.customText}
              </Typography>
            ) : (
              <Typography variant="body1" className={classes.text}>
                {user?.isClinician
                  ? user.joinAsParticipant
                    ? "You are about to join the call"
                    : "Please connect and join the call to open up the session with your client for this appointment"
                  : "You are about to join the waiting room to connect with your practitioner"}
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.content}>{props.children}</div>
        {!user && (
          <div className={classes.sideContainer}>
            <div className={classes.logoContainer}>
              <h2 className={classes.text}>Practitioner?</h2>
              {props.customText ? (
                <Typography variant="body1" className={classes.text}>
                  {props.customText}
                </Typography>
              ) : (
                <>
                  <Typography variant="body1" className={classes.text}>
                    Please enter as the host to start the session
                  </Typography>
                  <div className={classes.hostButton} onClick={handleNavigateToClinicianPage}>
                    <Typography variant="body1" className={classes.hostButtonText}>
                      Log in as session host
                    </Typography>
                    <Typography variant="body1" className={classes.hostButtonText}>
                      (for health care professionals)
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </Background>
  );
};

export default IntroContainer;
