import { useCallback, useEffect, useState } from "react";
import { AppointmentType, Provider } from "./helpers";
import { ParticipantType, getAppointmentTypesByClinicianId } from "../../services/schedService";
import { getClinicianProviders } from "../../services/billingService";
import { useAuth0 } from "@auth0/auth0-react";

export const useFetchProcessAppointmentData = ({
  accountId,
  clinicianId,
  appointmentId,
  participantType
}: {
  accountId: string;
  clinicianId: string;
  appointmentId: string;
  participantType: ParticipantType | undefined;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isListDataLoading, setIsListDataLoading] = useState<boolean>(false);
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>();
  const [providers, setProviders] = useState<Provider[]>();

  const fetchData = useCallback(async () => {
    setIsListDataLoading(true);
    const token: string = await getAccessTokenSilently();

    try {
      const [appointmentTypesResponse, providersResponse] = await Promise.all([
        getAppointmentTypesByClinicianId(token, true, clinicianId, participantType),
        getClinicianProviders(token, clinicianId)
      ]);
      const appointmentTypes =
        appointmentTypesResponse?.data?.data?.filter((appointmentType: AppointmentType) => appointmentType.isActive) ||
        [];
      const providers = providersResponse?.data?.providers?.filter((provider: Provider) => provider.active) || [];

      setAppointmentTypes(appointmentTypes);
      setProviders(providers);
    } catch (ex) {
      console.error(ex);
    } finally {
      setIsListDataLoading(false);
    }
  }, [clinicianId, participantType, getAccessTokenSilently]);

  useEffect(() => {
    if (accountId && clinicianId && appointmentId) {
      fetchData();
    }
  }, [accountId, clinicianId, appointmentId, fetchData]);

  return { isListDataLoading, appointmentTypes, providers, fetchData };
};
