import { Popper, makeStyles } from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import Switch from "../../Switch/Switch";
import Hidden from "../../Hidden/Hidden";
import BackgroundSelect from "./BackgroundSelect/BackgroundSelect";
import { useVideoContext } from "../../VideoProvider";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#ECEDEF",
    borderRadius: "4px"
  },
  control: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "#FFF",
    borderRadius: "4px",
    zIndex: 1,
    position: "relative"
  },
  settings: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    marginTop: "-4px",
    padding: "6px 0 2px 8px",
    fontSize: "12px",
    color: "#3F52FF",
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
    "&:not(:disabled)": {
      "&:hover, &:active": {
        backgroundColor: "#D9DCFF"
      }
    },
    "&:disabled": {
      color: "#BDBDBD",
      cursor: "default"
    }
  },
  overlay: {
    zIndex: 1
  }
}));

interface VideoInputSwitchProps {
  className?: string;
  disabled?: boolean;
}

const VideoInputSwitch = ({ className, disabled }: VideoInputSwitchProps) => {
  const classes = useStyles();

  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const { localTracks, videoInputDevices } = useVideoContext();

  const lastClickTimeRef = useRef(0);
  const backgroundSelectAnchorRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const [isBackgroundSelectVisible, setIsBackgroundSelectVisible] = useState(false);

  const handleClick = (e: any) => {
    if (
      !popperRef.current?.contains(e.target) &&
      e.target?.attributes?.["aria-describedby"]?.value !== "video-input-switch-background-button"
    ) {
      setIsBackgroundSelectVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  const isDisabled =
    (!localTracks.some((track) => track.kind === "video") && !videoInputDevices.some((device) => device.label)) ||
    disabled;

  return (
    <div className={clsx(classes.container, className)} ref={backgroundSelectAnchorRef}>
      <div className={classes.control}>
        <div>Video Camera</div>
        <Switch
          checked={isVideoEnabled}
          disabled={isDisabled}
          onClick={toggleVideo}
          onMouseDown={(e) => e.stopPropagation()}
        />
      </div>
      <button
        className={classes.settings}
        aria-describedby="video-input-switch-background-button"
        type="button"
        disabled={isDisabled}
        onClick={() => setIsBackgroundSelectVisible(!isBackgroundSelectVisible)}
      >
        Background
      </button>
      {backgroundSelectAnchorRef.current && (
        <>
          <Hidden smDown>
            <Popper
              id="video-input-switch-background-button"
              className={classes.overlay}
              ref={popperRef}
              anchorEl={backgroundSelectAnchorRef.current}
              placement="right-start"
              open={isBackgroundSelectVisible}
              modifiers={{
                offset: {
                  enabled: true,
                  offset: "0,12"
                }
              }}
            >
              <BackgroundSelect onClose={() => setIsBackgroundSelectVisible(false)} />
            </Popper>
          </Hidden>
          <Hidden mdUp>
            <Popper
              id="video-input-switch-background-button"
              className={classes.overlay}
              ref={popperRef}
              anchorEl={backgroundSelectAnchorRef.current}
              placement="bottom"
              open={isBackgroundSelectVisible}
              modifiers={{
                flip: {
                  enabled: false
                }
              }}
            >
              <BackgroundSelect onClose={() => setIsBackgroundSelectVisible(false)} />
            </Popper>
          </Hidden>
        </>
      )}
    </div>
  );
};

export default VideoInputSwitch;
