import { Path, useLocation, useNavigate, useParams } from "react-router-dom";
import Background from "../../components/Background/Background";
import { useVideoContext } from "../../components/VideoProvider";
import { useCallback, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { useAppState } from "../../state";
import { postClientRequestHelp } from "../../services/schedService";
import Loader from "../../components/Loader/Loader";

interface ExpectedState {
  name?: string;
  roomName?: string;
  joinRoom?: boolean;
  redirectPath?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    padding: "24px",
    maxHeight: "80%",
    maxWidth: "1280px",
    overflow: "auto"
  }
}));

const PaperformSurvey = () => {
  const { state } = useLocation() as Path & { state?: ExpectedState };
  const navigate = useNavigate();
  const { URLRoomName, surveyId } = useParams<{ URLRoomName?: string; surveyId?: string }>();

  const styles = useStyles();

  const { appointment, accountSettings, user } = useAppState();
  const { joinRoom } = useVideoContext();

  // TODO: loader if true
  const postSubmitRef = useRef(false);

  const postFormSubmit = useCallback(
    async (e?: any) => {
      // paperform event
      if (
        Array.isArray(e?.detail?.data) &&
        e.detail.data.some(
          (data: any) =>
            data?.key && data.value === "Yes" && accountSettings?.formSubmissionAlertFieldKeys?.includes(data.key)
        ) &&
        URLRoomName
      ) {
        await postClientRequestHelp(URLRoomName, { senderId: user?._id, name: user?.name || state?.name });
      }

      if (!postSubmitRef.current && state) {
        postSubmitRef.current = true;

        if (state.joinRoom && state.name && state.roomName) {
          await joinRoom(state.name, state.roomName);
        }

        if (state.redirectPath) {
          navigate(state.redirectPath);
        }
      }
    },
    [URLRoomName, accountSettings?.formSubmissionAlertFieldKeys, joinRoom, navigate, state, user?._id, user?.name]
  );

  useEffect(() => {
    if (!surveyId) {
      postFormSubmit();
    }
  }, [postFormSubmit, surveyId]);

  useEffect(() => {
    if (
      !Array.from(document.body.getElementsByTagName("script")).some(
        (elem) => elem.src === "https://paperform.co/__embed.min.js"
      )
    ) {
      const script = document.createElement("script");
      script.src = "https://paperform.co/__embed.min.js";
      document.body.appendChild(script);
    }

    window.addEventListener("PaperformSubmission", postFormSubmit);

    return () => {
      window.removeEventListener("PaperformSubmission", postFormSubmit);
    };
  }, [postFormSubmit]);

  return (
    <Background>
      <div className={styles.container}>
        {postSubmitRef.current ? (
          <Loader />
        ) : (
          <div
            data-paperform-id={surveyId}
            data-spinner={true}
            data-prefill={
              appointment &&
              `account_id=${appointment.accountId}&client_record_id=${appointment.clientRecord._id}&client_profile_id=${appointment.clientRecord.clientProfiles?.[0]._id}`
            }
          />
        )}
      </div>
    </Background>
  );
};

export default PaperformSurvey;
