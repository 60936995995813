import { Button, makeStyles } from "@material-ui/core";

import CallIcon from "@mui/icons-material/Call";

const useStyles = makeStyles(() => ({
  input: {
    border: 0,
    borderBottom: "1px solid #cbd5e1",
    padding: 10,
    fontSize: 18,
    marginBottom: 10,
    textAlign: "center"
  },
  name: {
    margin: 0
  },
  keypad: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    gap: 5
  },
  button: {
    borderRadius: 5,
    border: "1px solid #cbd5e1"
  },
  callContainer: {
    marginTop: 20
  }
}));

interface DiallerProps {
  number: string;
  setNumber: React.Dispatch<React.SetStateAction<string>>;
  firstName?: string;
  displayName: boolean;
  handleCall: () => void;
  disabled: boolean;
}
const Dialler = ({ number, setNumber, handleCall, firstName, displayName, disabled }: DiallerProps) => {
  const styles = useStyles();

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(event.target.value);
  };

  const handleBackSpace = () => {
    setNumber(number.substring(0, number.length - 1));
  };

  const handleNumberPressed = (newNumber: string) => () => {
    setNumber((prev) => prev + newNumber);
  };

  return (
    <>
      {firstName && displayName && <p className={styles.name}>{firstName}</p>}
      <input type="tel" value={number} onChange={handleNumberChange} className={styles.input} />
      <div className={styles.keypad}>
        <Button onClick={handleNumberPressed("1")} className={styles.button}>
          1
        </Button>
        <Button onClick={handleNumberPressed("2")} className={styles.button}>
          2
        </Button>
        <Button onClick={handleNumberPressed("3")} className={styles.button}>
          3
        </Button>
        <Button onClick={handleNumberPressed("4")} className={styles.button}>
          4
        </Button>
        <Button onClick={handleNumberPressed("5")} className={styles.button}>
          5
        </Button>
        <Button onClick={handleNumberPressed("6")} className={styles.button}>
          6
        </Button>
        <Button onClick={handleNumberPressed("7")} className={styles.button}>
          7
        </Button>
        <Button onClick={handleNumberPressed("8")} className={styles.button}>
          8
        </Button>
        <Button onClick={handleNumberPressed("9")} className={styles.button}>
          9
        </Button>
        <Button onClick={handleNumberPressed("+")} className={styles.button}>
          +
        </Button>
        <Button onClick={handleNumberPressed("0")} className={styles.button}>
          0
        </Button>
        {number.length > 0 && (
          <Button onClick={handleBackSpace} className={styles.button}>
            &lt;&lt;
          </Button>
        )}
      </div>
      <div className={styles.callContainer}>
        <Button onClick={handleCall} disabled={number.length < 5 || disabled} color="primary">
          <CallIcon />
        </Button>
      </div>
    </>
  );
};

export default Dialler;
