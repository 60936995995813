import {
  ClinicianMedicareRole,
  MedicareItem,
  MedicareItemFormat,
  MedicareItemMode,
  MedicareItemDuration
} from "./interfaces";

export const MENTAL_HEALTH_NURSE_ITEMS: MedicareItem[] = [
  {
    mbsCode: "10956",
    description:
      "Mental health service provided by an eligible mental health worker, lasting at least 20 minutes, to a person who has a chronic condition and complex care needs being managed by a medical practitioner (including a general practitioner, but not a specialist or consultant physician) under a shared care plan or under both a GP Management Plan and Team Care Arrangements or, if the person is a resident of an aged care facility, the person's medical practitioner has contributed to a multidisciplinary care plan.",
    benefit: 60.35,
    role: ClinicianMedicareRole.MentalHealthNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan20
  },
  {
    mbsCode: "81010",
    description:
      "Provision of a non-directive pregnancy support counselling service, lasting at least 30 minutes, to a person who is currently pregnant or who has been pregnant in the preceding 12 months, by an eligible mental health nurse, where the patient is referred to the mental health nurse by a medical practitioner.",
    benefit: 70.85,
    role: ClinicianMedicareRole.MentalHealthNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneMoreThan30
  },
  {
    mbsCode: "93026",
    description:
      "Provision of a non-directive pregnancy support counselling service as a telehealth attendance, lasting at least 30 minutes, to a person who is currently pregnant or who has been pregnant in the preceding 12 months, by an eligible mental health nurse, where the patient is referred to the mental health nurse by a medical practitioner.",
    benefit: 70.85,
    role: ClinicianMedicareRole.MentalHealthNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan30
  },
  {
    mbsCode: "93029",
    description:
      "Provision of a non-directive pregnancy support counselling service as a phone attendance, lasting at least 30 minutes, to a person who is currently pregnant or who has been pregnant in the preceding 12 months, by an eligible mental health nurse, where the patient is referred to the mental health nurse by a medical practitioner.",
    benefit: 70.85,
    role: ClinicianMedicareRole.MentalHealthNurse,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan30
  }
];
