export enum ClinicianMedicareRole {
  ClinicalPsychologists = "clinicalPsychologists",
  RegisteredPsychologists = "registeredPsychologists",
  OccupationalTherapists = "occupationalTherapists",
  SocialWorkers = "socialWorkers",
  GeneralPractitioner = "generalPractitioner",
  Dietitian = "dietitian",
  PracticeNurse = "practiceNurse",
  MentalHealthNurse = "mentalHealthNurse",
  NursePractitioner = "nursePractitioner",
  SpeechPathologist = "speechPathologist",

  // used for Medicare claim items only, not used for actual roles 10/05/2023
  // https://www.servicesaustralia.gov.au/recognition-allied-health-professional-to-provide-medicare-services
  MentalHealthWorker = "mentalHealthWorker", // includes both psychologists, occupational therapists, social worker
  Psychologist = "psychologist", // includes both psychologists
  AlliedHealthProfessional = "alliedHealthProfessional"
}

export enum MedicareItemFormat {
  OneToOne = "oneToOne",
  Group = "group"
}

export enum MedicareItemMode {
  FaceToFace = "faceToFace",
  VideoCall = "videoCall",
  PhoneCall = "phoneCall",
  Telehealth = "telehealth",
  Any = "any"
}

export interface MedicareItem {
  mbsCode: string;
  description: string;
  benefit: number;
  role: ClinicianMedicareRole;
  duration: MedicareItemDuration;
  format: MedicareItemFormat;
  mode: MedicareItemMode;
  disabled?: boolean;
}

export enum MedicareItemDuration {
  OneToOneLessThan20 = "<20",
  OneToOneMoreThan20 = ">20",
  OneToOneMoreThan30 = ">30",
  OneToOneLessThan40 = "<40",
  OneToOneMoreThan40 = ">40",
  OneToOneMoreThan45 = ">45",
  OneToOneLessThan50 = "<50",
  OneToOneMoreThan50 = ">50",
  GroupMoreThan60 = ">60",
  Any = "any"
}

export interface DVAItem {
  code: string;
  description: string;
  benefit: number;
  duration: MedicareItemDuration;
  mode: MedicareItemMode;
}
