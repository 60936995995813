import {
  ClinicianMedicareRole,
  MedicareItem,
  MedicareItemFormat,
  MedicareItemMode,
  MedicareItemDuration
} from "./interfaces";

export const GENERAL_PRACTITIONER_ITEMS: MedicareItem[] = [
  {
    mbsCode: "283",
    description:
      "Professional attendance at consulting rooms by a medical practitioner, for providing focused psychological strategies for assessed mental disorders by a medical practitioner registered with the Chief Executive Medicare as meeting the credentialing requirements for provision of this service, lasting at least 30 minutes but less than 40 minutes",
    benefit: 84.55,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneLessThan40
  },
  {
    mbsCode: "286",
    description:
      "Professional attendance at consulting rooms by a medical practitioner, for providing focused psychological strategies for assessed mental disorders by a medical practitioner registered with the Chief Executive Medicare as meeting the credentialing requirements for provision of this service, lasting at least 40 minutes",
    benefit: 121,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneMoreThan40
  },
  {
    mbsCode: "2700",
    description:
      "Professional attendance by a general practitioner (including a general practitioner who has not undertaken mental health skills training) of at least 20 minutes but less than 40 minutes in duration for the preparation of a GP mental health treatment plan for a patient.",
    benefit: 81.7,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneLessThan40
  },
  {
    mbsCode: "2701",
    description:
      "Professional attendance by a general practitioner (including a general practitioner who has not undertaken mental health skills training) of at least 40 minutes in duration for the preparation of a GP mental health treatment plan for a patient.",
    benefit: 120.25,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneMoreThan40
  },
  {
    mbsCode: "2712",
    description:
      "Professional attendance by a general practitioner to review a GP mental health treatment plan which the general practitioner, or an associated general practitioner has prepared, or to review a Psychiatrist Assessment and Management Plan.",
    benefit: 81.7,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: "2713",
    description:
      "Professional attendance by a general practitioner in relation to a mental disorder and of at least 20 minutes in duration, involving taking relevant history and identifying the presenting problem (to the extent not previously recorded), providing treatment and advice and, if appropriate, referral for other services or treatments, and documenting the outcomes of the consultation.",
    benefit: 81.7,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: "2715",
    description:
      "Professional attendance by a general practitioner (including a general practitioner who has undertaken mental health skills training) of at least 20 minutes but less than 40 minutes in duration for the preparation of a GP mental health treatment plan for a patient.",
    benefit: 103.7,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneLessThan40
  },
  {
    mbsCode: "2717",
    description:
      "Professional attendance by a general practitioner (including a general practitioner who has undertaken mental health skills training) of at least 40 minutes in duration for the preparation of a GP mental health treatment plan for a patient.",
    benefit: 152.8,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneMoreThan40
  },
  {
    mbsCode: "92116",
    description:
      "Telehealth attendance by a general practitioner who has undertaken mental health skills training, of at least 20 minutes but less than 40 minutes in duration for the preparation of a GP mental health treatment plan for a patient.",
    benefit: 103.7,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan40
  },
  {
    mbsCode: "92117",
    description:
      "Telehealth attendance by a general practitioner who has undertaken mental health skills training, of at least 40 minutes in duration for the preparation of a GP mental health treatment plan for a patient.",
    benefit: 152.8,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan40
  },
  {
    mbsCode: "92112",
    description:
      "Telehealth attendance, by a general practitioner who has not undertaken mental health skills training (and not including a specialist or consultant physician), of at least 20 minutes but less than 40 minutes in duration for the preparation of a GP mental health treatment plan for a patient.",
    benefit: 81.7,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan40
  },
  {
    mbsCode: "92113",
    description:
      "Telehealth attendance, by a general practitioner who has not undertaken mental health skills training (and not including a specialist or consultant physician), of at least 40 minutes in duration for the preparation of a GP mental health treatment plan for a patient.",
    benefit: 120.25,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan40
  },
  {
    mbsCode: "92114",
    description:
      "Telehealth attendance by a general practitioner to review a GP mental health treatment plan which the general practitioner, or an associated general practitioner has prepared, or to review a Psychiatrist Assessment and Management Plan.",
    benefit: 81.7,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: "92115",
    description:
      "Telehealth attendance by a general practitioner in relation to a mental disorder and of at least 20 minutes in duration, involving taking relevant history and identifying the presenting problem (to the extent not previously recorded), providing treatment and advice and, if appropriate, referral for other services or treatments, and documenting the outcomes of the consultation.",
    benefit: 81.7,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan20
  },
  {
    mbsCode: "92126",
    description:
      "Phone attendance by a general practitioner to review a GP mental health treatment plan which the general practitioner, or an associated general practitioner has prepared, or to review a Psychiatrist Assessment and Management Plan.",
    benefit: 81.7,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.Any
  },
  {
    mbsCode: "92127",
    description:
      "Phone attendance by a general practitioner in relation to a mental disorder and of at least 20 minutes in duration, involving taking relevant history and identifying the presenting problem (to the extent not previously recorded), providing treatment and advice and, if appropriate, referral for other services or treatments, and documenting the outcomes of the consultation.",
    benefit: 81.7,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan20
  },
  {
    mbsCode: "91820",
    description:
      "Telehealth attendance by a medical practitioner (not including a general practitioner, specialist or consultant physician), for the purpose of providing focused psychological strategies for assessed mental disorders, lasting at least 30 minutes but less than 40 minutes.",
    benefit: 84.55,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan40
  },
  {
    mbsCode: "91821",
    description:
      "Telehealth attendance by a medical practitioner (not including a general practitioner, specialist or consultant physician), for the purpose of providing focused psychological strategies for assessed mental disorders, lasting at least 40 minutes.",
    benefit: 121,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan40
  },
  {
    mbsCode: "91844",
    description:
      "Phone attendance by a medical practitioner (not including a general practitioner, specialist or consultant physician), for the purpose of providing focused psychological strategies for assessed mental disorders, lasting at least 30 minutes but less than 40 minutes.",
    benefit: 84.55,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan40
  },
  {
    mbsCode: "91845",
    description:
      "Phone attendance by a medical practitioner (not including a general practitioner, specialist or consultant physician), for the purpose of providing focused psychological strategies for assessed mental disorders, lasting at least 40 minutes.",
    benefit: 121,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan40
  },
  {
    mbsCode: "930",
    description:
      "Attendance by a general practitioner, as a member of a multidisciplinary case conference team, to organise and coordinate a mental health case conference, lasting at least 15 minutes but less than 20 minutes",
    benefit: 80.55,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneLessThan20
  },
  {
    mbsCode: "933",
    description:
      "Attendance by a general practitioner, as a member of a multidisciplinary case conference team, to organise and coordinate a mental health case conference, lasting at least 20 minutes but less than 40 minutes",
    benefit: 137.75,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneLessThan40
  },
  {
    mbsCode: "935",
    description:
      "Attendance by a general practitioner, as a member of a multidisciplinary case conference team, to organise and coordinate a mental health case conference, lasting at least 40 minutes",
    benefit: 229.65,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneMoreThan40
  },
  {
    mbsCode: "937",
    description:
      "Attendance by a general practitioner, as a member of a multidisciplinary case conference team, to participate in a mental health case conference, lasting at least 15 minutes but less than 20 minutes",
    benefit: 59.2,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneLessThan20
  },
  {
    mbsCode: "943",
    description:
      "Attendance by a general practitioner, as a member of a multidisciplinary case conference team, to participate in a mental health case conference, lasting at least 20 minutes but less than 40 minutes",
    benefit: 101.45,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneLessThan40
  },
  {
    mbsCode: "945",
    description:
      "Attendance by a general practitioner, as a member of a multidisciplinary case conference team, to participate in a mental health case conference, lasting at least 40 minutes",
    benefit: 168.8,
    role: ClinicianMedicareRole.GeneralPractitioner,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Any,
    duration: MedicareItemDuration.OneToOneMoreThan40
  }
];
