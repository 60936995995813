import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppState } from "../../../state";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getWaitroomParticipantForParticipant,
  getWaitroomParticipantsForHost,
  WaitroomParticipant
} from "../../../services/schedService";
import { AxiosError } from "axios";
import { Room } from "twilio-video";

export const useWaitroom = ({
  room,
  initialiseVideoRoom,
  rejoinRoom
}: {
  room: Room | null;
  initialiseVideoRoom: (token: string) => Promise<void>;
  rejoinRoom: (name: string, roomId: string) => void;
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const { user, setError } = useAppState();

  const [participants, setParticipants] = useState<WaitroomParticipant[]>([]);
  const [userParticipant, setUserParticipant] = useState<WaitroomParticipant>();

  const [waitroomToken, setWaitroomToken] = useState<string>();

  const isConnectedRef = useRef(false);
  const isPollingWaitroomRef = useRef(false);

  const resetWaitroom = useCallback(() => {
    isConnectedRef.current = false;
    isPollingWaitroomRef.current = false;

    setParticipants([]);
    setUserParticipant(undefined);
    setWaitroomToken(undefined);
  }, []);

  const callGetWaitroomForParticipant = useCallback(
    async ({
      roomId,
      participantId,
      participantName,
      token,
      poll
    }: {
      roomId: string;
      participantId: string;
      participantName: string;
      token: string;
      // currently only true if in waitroom
      poll: boolean;
    }) => {
      // if polling, and !isPollingWaitroomRef, return
      if (!isPollingWaitroomRef.current && poll) {
        return;
      }

      try {
        const { data, status } = await getWaitroomParticipantForParticipant({
          roomId,
          participantId,
          token,
          returnToken: !isConnectedRef.current
        });

        // room ended
        if (status === 204) {
          resetWaitroom();
          return;
        }

        if (data) {
          const { participant, participants, token: twilioAuthToken } = data;

          if (!isConnectedRef.current && participant.isAdmitted && twilioAuthToken) {
            await initialiseVideoRoom(twilioAuthToken);
            isConnectedRef.current = true;
            isPollingWaitroomRef.current = false;
          }

          setParticipants(participants);
          setUserParticipant(participant);
        }
      } catch (ex) {
        if (ex instanceof AxiosError) {
          if (ex.response?.status === 404 && ex.response?.data?.message === "This room has been ended by the host.") {
            if (ex.response.data.rejoinWaitroom) {
              console.log("Rejoining waitroom");
              rejoinRoom(participantName, roomId);
            } else if (poll) {
              console.warn("Waitroom not found, exiting");
              // exit if waitroom not found
              resetWaitroom();
            }

            return;
          } else if (ex.response?.status === 403) {
            // exit if participant not found
            if (poll) {
              resetWaitroom();
            }
            console.warn("Participant removed from waitroom");
            setError({
              code: 403,
              message:
                ex.response?.data?.message || "Unable to connect to waitroom. Please refresh the page to reconnect.",
              name: "ParticipantRemovedError"
            });
            return;
          } else if (ex.response?.status === 401) {
            // exit if token expired
            if (poll) {
              resetWaitroom();
            }
            console.warn("Participant token expired");
            setError({
              code: 401,
              message: "Disconnected from waitroom. Please refresh the page to reconnect.",
              name: "ParticipantRemovedError"
            });
            return;
          } else if (ex.response?.status === 400) {
            // exit if token expired
            if (poll) {
              resetWaitroom();
            }
            console.warn("Invalid time to join room");
            setError({
              code: 400,
              message: "Disconnected from waitroom. Please refresh the page to reconnect.",
              name: "ParticipantRemovedError"
            });
            return;
          } else {
            // keep trying here
            console.dir(ex);
            console.error("Failed to refetch waitroom.");

            // increase delay to 6s in case of server down/overloaded
            if (poll) {
              setTimeout(
                () =>
                  callGetWaitroomForParticipant({
                    roomId,
                    participantId,
                    participantName,
                    token,
                    poll
                  }),
                10000
              );
            }

            return;
          }
        } else {
          // exit if random error
          if (poll) {
            resetWaitroom();
          }
          console.warn("Error while fetching waitroom");
          console.error(ex);
          return;
        }
      }

      if (poll) {
        setTimeout(
          () =>
            callGetWaitroomForParticipant({
              roomId,
              participantId,
              participantName,
              token,
              poll
            }),
          5000
        );
      }
    },
    [initialiseVideoRoom, rejoinRoom, resetWaitroom, setError]
  );

  const callGetWaitroomForHost = useCallback(
    async ({ roomId }: { roomId: string }) => {
      if (!isPollingWaitroomRef.current) {
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        const { data, status } = await getWaitroomParticipantsForHost(token, roomId);

        // room ended
        if (status === 204) {
          resetWaitroom();
          return;
        }

        if (data) {
          const { participants } = data;
          setParticipants(participants);
        }
      } catch (ex) {
        // exit if waitroom not found

        if (ex instanceof AxiosError) {
          if (
            ex.response?.status === 404 &&
            ["This room has been ended by the host.", "No appointments found"].includes(ex.response?.data?.message)
          ) {
            console.warn("Waitroom not found, exiting");
            setError({
              code: 404,
              message: "Unable to connect to waitroom. Please refresh the page to reconnect.",
              name: "WaitroomNotFoundError"
            });
            return;
          } else {
            // keep trying here
            console.dir(ex);
            console.error("Failed to refetch waitroom.");

            // increase delay to 6s in case of server down/overloaded
            setTimeout(() => callGetWaitroomForHost({ roomId }), 20000);
            return;
          }
        } else {
          // exit if random error
          console.error(ex);
          return;
        }
      }

      setTimeout(() => callGetWaitroomForHost({ roomId }), 10000);
    },
    [getAccessTokenSilently, resetWaitroom, setError]
  );

  const initialiseParticipantWaitroom = (roomId: string, userParticipant: WaitroomParticipant, token: string) => {
    setUserParticipant(userParticipant);
    setWaitroomToken(token);

    isPollingWaitroomRef.current = true;

    callGetWaitroomForParticipant({
      roomId,
      participantId: userParticipant.participantId,
      participantName: userParticipant.name,
      token,
      poll: true
    });
  };

  const initialiseHostWaitroom = (
    roomId: string,
    participants: WaitroomParticipant[],
    userParticipant: WaitroomParticipant
  ) => {
    setParticipants(participants);
    setUserParticipant(userParticipant);

    isPollingWaitroomRef.current = true;
    isConnectedRef.current = true;

    callGetWaitroomForHost({ roomId });
  };

  // for clients, we stop polling after they're admitted and they connect
  // this refreshes the waitroom when a participant joins, to update the waitlist,
  // because we use the waitlist to get participant names
  useEffect(() => {
    if (room) {
      const fetchWaitroomWhenParticipantConnect = async () => {
        if ((user?.isClinician || user?.joinAsParticipant) && userParticipant && waitroomToken) {
          callGetWaitroomForParticipant({
            roomId: room.name,
            participantId: userParticipant.participantId,
            participantName: userParticipant.name,
            token: waitroomToken,
            poll: false
          });
        }
      };

      room.on("participantConnected", fetchWaitroomWhenParticipantConnect);
      return () => {
        room.off("participantConnected", fetchWaitroomWhenParticipantConnect);
      };
    }
  }, [
    callGetWaitroomForHost,
    callGetWaitroomForParticipant,
    room,
    user?.isClinician,
    user?.joinAsParticipant,
    userParticipant,
    waitroomToken
  ]);

  const isCurrentUserInWaitingRoom = useMemo(
    () => (!user?.isClinician || !!user.joinAsParticipant) && !!userParticipant && !userParticipant?.isAdmitted,
    [user?.isClinician, user?.joinAsParticipant, userParticipant]
  );

  return {
    participants,
    userParticipant,
    isCurrentUserInWaitingRoom,
    initialiseHostWaitroom,
    initialiseParticipantWaitroom,
    setParticipants,
    resetWaitroom
  };
};
