import { useAppStateContext } from "../../AppStateProvider/AppStateProvider";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ErrorIcon from "../../../icons/ErrorIcon";
import Hello from "./Hello.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      justifyContent: "space-between"
    },
    /* 
    The size of the image is explicitly stated here so that this content can properly be centered vertically
    before the image is loaded.
    */
    illustration: {
      height: "284px",
      marginTop: "3em",
      [theme.breakpoints.down("md")]: {
        height: "200px"
      }
    }
  })
);

const GetStarted = () => {
  const { nextPane, state } = useAppStateContext();
  const classes = useStyles();

  return (
    <Container>
      <Grid container alignItems="center" className={classes.gridContainer}>
        <Grid item lg={5}>
          {state.appIsExpired ? (
            <>
              <Typography variant="h1" gutterBottom>
                <ErrorIcon />
                App has expired.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Please re-deploy the application and try again.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h1" gutterBottom>
                Let's get started.
              </Typography>

              <Typography variant="body1" gutterBottom>
                We'll help you solve any video troubles you're experiencing but first, let's check your setup.
              </Typography>
            </>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={nextPane}
            style={{ marginBottom: "1em" }}
            disabled={state.appIsExpired}
          >
            Get started
          </Button>
        </Grid>

        <Grid item lg={5}>
          <img src={Hello} alt="Hello" className={classes.illustration} />
          <Typography variant="body1" color="textSecondary" gutterBottom>
            <strong>Not sure about something?</strong> Skip that section for now, and if you get really stuck reach out
            to us via our online support desk.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GetStarted;
