const CopyIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6812 3.59414C14.6188 2.97958 14.0998 2.5 13.4688 2.5H3.71875L3.59414 2.50629C2.97958 2.5687 2.5 3.08772 2.5 3.71875V13.4688L2.50629 13.5934C2.5687 14.2079 3.08772 14.6875 3.71875 14.6875H5.3125V16.0938C5.3125 16.8704 5.9421 17.5 6.71875 17.5H16.0938C16.8704 17.5 17.5 16.8704 17.5 16.0938V6.71875C17.5 5.9421 16.8704 5.3125 16.0938 5.3125H14.6875V3.71875L14.6812 3.59414ZM13.75 5.3125V3.71875C13.75 3.58561 13.6575 3.47408 13.5332 3.44493L13.4688 3.4375H3.71875C3.58561 3.4375 3.47408 3.53001 3.44493 3.65426L3.4375 3.71875V13.4688C3.4375 13.6019 3.53001 13.7134 3.65426 13.7426L3.71875 13.75H5.3125V6.71875C5.3125 5.9421 5.9421 5.3125 6.71875 5.3125H13.75ZM6.71875 6.25H16.0938C16.3526 6.25 16.5625 6.45987 16.5625 6.71875V16.0938C16.5625 16.3526 16.3526 16.5625 16.0938 16.5625H6.71875C6.45987 16.5625 6.25 16.3526 6.25 16.0938V6.71875C6.25 6.45987 6.45987 6.25 6.71875 6.25Z"
        fill="#606B85"
      />
    </svg>
  );
};

export default CopyIcon;
