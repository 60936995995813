import Loader from "../Loader/Loader";
import BaseVideoApp from "./BaseVideoApp";
import useAuthValidating from "../../hooks/useAuthValidating";

const ParticipantVideoApp = () => {
  const { isRedirecting } = useAuthValidating();

  if (isRedirecting) {
    return <Loader />;
  }
  return <BaseVideoApp />;
};

export default ParticipantVideoApp;
