export enum TelehealthRoomRole {
  Host = "host",
  Participant = "participant"
}

export const getIdentityName = (identity: string) => {
  const identityArr = identity.split(":");
  if (identityArr.length !== 2) {
    console.error(`Invalid identity ${identity}`);
    return "";
  }
  const role = identityArr[0];
  // Put `Clinician: ` at the beginning of the host name
  return `${role === TelehealthRoomRole.Host ? "Clinician: " : ""}${identityArr[1]}`;
};
