import { useEffect, useState } from "react";
import { getAppointmentVoiceCallAccessToken, getVoiceCallAccessToken } from "../../services/schedService";
import { useAuth0 } from "@auth0/auth0-react";
import { AxiosError } from "axios";
import { useAppState } from "../../state";
import { useNavigate } from "react-router-dom";

/**
 * Only for protected clinician routes
 */
const useGetVoiceCallToken = (appointmentId?: string) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { setError, setAppointment } = useAppState();
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const [clientPhoneNumber, setClientPhoneNumber] = useState("");
  const [clientFirstName, setClientFirstName] = useState("");

  const getVoiceCallToken = async () => {
    setIsLoading(true);

    let token = "";
    try {
      token = await getAccessTokenSilently();
    } catch (_) {
      setError({
        code: 400,
        message: "There's an error occurred",
        name: "Failed to Join"
      });
      if (appointmentId) {
        navigate("/calls");
      }
      return setIsLoading(false);
    }

    if (appointmentId) {
      await getAppointmentVoiceCallAccessToken(token, appointmentId)
        .then((res) => {
          setToken(res.data.token);
          setClientPhoneNumber(res.data.mobileNumber);
          setClientFirstName(res.data.firstName);
          setClientFirstName(res.data.firstName);
          setAppointment(res.data.appointment);
          return setIsLoading(false);
        })
        .catch((ex) => {
          if (ex instanceof AxiosError) {
            setError({
              code: ex.response?.status && typeof ex.response.status === "number" ? ex.response.status : 500,
              message: ex.response?.data?.message || ex.message,
              name: ex.name
            });
            navigate("/calls");
            return setIsLoading(false);
          }
        });
    } else {
      await getVoiceCallAccessToken(token)
        .then((res) => {
          setToken(res.data.token);
          return setIsLoading(false);
        })
        .catch((ex) => {
          if (ex instanceof AxiosError) {
            setError({
              code: ex.response?.status && typeof ex.response.status === "number" ? ex.response.status : 500,
              message: ex.response?.data?.message || ex.message,
              name: ex.name
            });
            return setIsLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    getVoiceCallToken();
    // eslint-disable-next-line
  }, [appointmentId]);

  return {
    isLoading,
    token,
    client: {
      phoneNumber: clientPhoneNumber,
      firstName: clientFirstName
    }
  };
};

export default useGetVoiceCallToken;
