import { makeStyles } from "@material-ui/core";

import TacklitFullLogo from "../../images/tacklit/tacklit-full-logo.png";
import PoweredLogo from "../../images/tacklit/powered-by-tacklit.png";

const useStyles = makeStyles(() => ({
  footerWrapper: {
    width: "100%",
    backgroundColor: "#F5F6FF",
    display: "flex",
    alignItems: "center",
    gap: "1em",
    padding: "0.3em"
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footerWrapper}>
      <img src={TacklitFullLogo} alt="tacklit-full-logo" />
      <img src={PoweredLogo} alt="powered-by-tacklit" />
    </div>
  );
};

export default Footer;
