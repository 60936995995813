import { Divider, FormControl, MenuItem, Select, createStyles, makeStyles } from "@material-ui/core";
import { AppointmentSlots, AppointmentType, DELIVERY_TYPE_LABELS, DeliveryType } from "./helpers";
import { FormError } from "./helpers";
import { useMemo } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: "#f5f6ff",
      border: "1px solid #dadbde",
      borderRadius: "4px",
      padding: "14px 20px",
      display: "flex",
      alignItems: "center",
      gap: "16px"
    },
    clientName: {
      maxWidth: "250px",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
      color: "#414449"
    },
    appointmentType: {
      maxWidth: "580px"
    },
    deliveryType: {
      maxWidth: "150px"
    },
    select: {
      "&:before": {
        borderColor: "transparent"
      },
      "&:after": {
        borderColor: "transparent",
        borderBottomWidth: "1px"
      },
      "& .MuiSelect-icon": {
        position: "relative"
      },
      "& .MuiSelect-select": {
        color: "#414449",
        fontSize: "1rem",
        padding: "4px 0"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent"
      },
      "&.error": {
        borderColor: "#d54141"
      },
      "& .MuiSelect-selectMenu": {
        whiteSpace: "break-spaces",
        textOverflow: "initial"
      }
    },
    labelWrapper: {
      paddingRight: "8px"
    }
  })
);

interface AppointmentBasicDetailsProps {
  appointment?: AppointmentSlots;
  appointmentTypes?: AppointmentType[];
  selectedAppointmentType?: AppointmentType;
  onSelectAppointmentType: (appointmentType: AppointmentType) => void;
  selectedDeliveryType?: DeliveryType;
  onSelectDeliveryType: (deliveryType: DeliveryType) => void;
  formError: FormError;
}

const AppointmentBasicDetails = ({
  appointment,
  appointmentTypes,
  selectedAppointmentType,
  onSelectAppointmentType,
  selectedDeliveryType,
  onSelectDeliveryType,
  formError
}: AppointmentBasicDetailsProps) => {
  const classes = useStyles();

  const appointmentTypeOptions = useMemo(
    () =>
      appointmentTypes
        ? appointmentTypes.map((item) => ({
            value: item._id,
            label: item.name
          }))
        : [],
    [appointmentTypes]
  );

  const deliveryModeOptions = useMemo(
    () =>
      selectedAppointmentType && appointmentTypes
        ? appointmentTypes
            .filter((item) => item._id === selectedAppointmentType?._id)[0]
            ?.deliveryOptions.map((item: DeliveryType) => ({
              value: item,
              label: DELIVERY_TYPE_LABELS[item]
            }))
        : [],
    [appointmentTypes, selectedAppointmentType]
  );

  return (
    <div className={classes.container}>
      <div className={classes.clientName}>{appointment?.clientRecord.clientProfiles?.[0]?.name}</div>
      <Divider orientation="vertical" flexItem={true} />
      <FormControl className={classes.appointmentType}>
        <Select
          onChange={(e) =>
            onSelectAppointmentType(appointmentTypes?.find((item) => item._id === e.target.value) as AppointmentType)
          }
          value={selectedAppointmentType?._id || ""}
          variant="standard"
          className={classes.select}
          error={formError.selectedAppointmentType}
        >
          {appointmentTypeOptions.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              <div className={classes.labelWrapper}>{option.label}</div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider orientation="vertical" flexItem={true} />

      <FormControl className={classes.deliveryType}>
        <Select
          displayEmpty
          onChange={(e) => onSelectDeliveryType(e.target.value as DeliveryType)}
          value={selectedDeliveryType?.toString() || ""}
          variant="standard"
          className={classes.select}
          error={formError.selectedDeliveryType}
          renderValue={(value) =>
            !value ? (
              <div className={classes.labelWrapper}>Delivery Type</div>
            ) : (
              <div className={classes.labelWrapper}>
                {selectedDeliveryType ? DELIVERY_TYPE_LABELS[selectedDeliveryType] : ""}
              </div>
            )
          }
        >
          {deliveryModeOptions.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              <div className={classes.labelWrapper}>{option.label}</div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AppointmentBasicDetails;
