import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";

const useStyles = makeStyles(() => ({
  box: {
    padding: "5px 10px",
    borderRadius: 4,
    marginBottom: 10
  }
}));

export enum CallState {
  CONNECTING = "Connecting",
  READY = "Ready",
  INCOMING = "Incoming",
  ON_CALL = "On call",
  OFFLINE = "Offline"
}

const CallConnection = ({ state }: { state: CallState }) => {
  const styles = useStyles();

  const colorStyles = useMemo(() => {
    switch (state) {
      case CallState.CONNECTING:
      case CallState.INCOMING:
        return {
          color: "black",
          backgroundColor: "yellow"
        };
      case CallState.READY:
      case CallState.ON_CALL:
        return {
          color: "white",
          backgroundColor: "green"
        };
      case CallState.OFFLINE:
        return {
          color: "white",
          backgroundColor: "red"
        };
      default:
        return {};
    }
  }, [state]);

  return (
    <div className={styles.box} style={colorStyles}>
      Connection: {state}
    </div>
  );
};

export default CallConnection;
