import {
  // Button,
  Drawer,
  makeStyles
} from "@material-ui/core";
import { useEffect } from "react";
import { useVideoContext } from "../VideoProvider";
import { removeParticipant } from "../../services/schedService";
import { useAuth0 } from "@auth0/auth0-react";
import DialogHeader from "../BackgroundSelectionDialog/BackgroundSelectionHeader/BackgroundSelectionHeader";
import WaitingRoomParticipant from "./WaitingRoomParticipant/WaitingRoomParticipant";

const useStyles = makeStyles((theme) => ({
  drawer: {
    display: "flex",
    width: theme.rightDrawerWidth,
    height: `calc(100% - ${theme.footerHeight}px)`
  },
  thumbnailContainer: {
    display: "flex",
    flexWrap: "wrap",
    overflowY: "auto"
  }
}));

const WaitroomAdminControl = () => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const {
    room,
    participantsInWaitroom,
    isAdminControlOpen,
    setIsAdminControlOpen,
    handleAcceptClientRequestsToJoinRoom,
    updateParticipantsList
  } = useVideoContext();

  const handleRemoveParticipant = async (participantId: string) => {
    const token = await getAccessTokenSilently();
    const {
      data: { participants }
    } = await removeParticipant(token, room!.name, participantId);
    if (participants) {
      updateParticipantsList(participants);
    }
  };

  useEffect(() => {
    if (participantsInWaitroom.length > 0) {
      setIsAdminControlOpen(true);
    }
  }, [participantsInWaitroom.length, setIsAdminControlOpen]);

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isAdminControlOpen}
      transitionDuration={0}
      classes={{
        paper: classes.drawer
      }}
    >
      <DialogHeader title="In Waiting Room" onClose={() => setIsAdminControlOpen(false)} />
      <div className={classes.thumbnailContainer}>
        {participantsInWaitroom.map((participant) => (
          <WaitingRoomParticipant
            key={participant.participantId}
            participant={participant}
            onAdmit={(participantId) => handleAcceptClientRequestsToJoinRoom([participantId])}
            onReject={handleRemoveParticipant}
          />
        ))}
      </div>
      {/* {participantsInWaitroom.length > 1 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            padding: 10
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAcceptClientRequestsToJoinRoom(participantsInWaitroom.map((p) => p.participantId))}
          >
            Admit all
          </Button>
        </div>
      )} */}
    </Drawer>
  );
};

export default WaitroomAdminControl;
