import { useCallback, useRef } from "react";

import Button from "@material-ui/core/Button";
import VideoOffIcon from "../../../icons/VideoOffIcon";
import VideoOnIcon from "../../../icons/VideoOnIcon";

import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import { useVideoContext } from "../../VideoProvider";

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { isAcquiringLocalTracks, localTracks, videoInputDevices } = useVideoContext();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  const isDisabled =
    isAcquiringLocalTracks ||
    (!localTracks.some((track) => track.kind === "video") && !videoInputDevices.some((device) => device.label));

  return (
    <Button
      className={props.className}
      onClick={toggleVideo}
      disabled={isDisabled || props.disabled}
      startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
    >
      {isDisabled ? "No Video" : isVideoEnabled ? "Stop Video" : "Start Video"}
    </Button>
  );
}
