import { ReactNode, useEffect } from "react";
import Loader from "../Loader/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const defaultReturnTo = () => `${window.location.pathname}${window.location.search}`;

interface LoginWrapperProps {
  children: ReactNode;
  returnTo?: string | (() => string);
}

const LoginWrapper = ({ children, returnTo = defaultReturnTo }: LoginWrapperProps) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    navigate("/login", {
      state: {
        returnTo: typeof returnTo === "function" ? returnTo() : returnTo
      }
    });
  }, [isAuthenticated, isLoading, navigate, returnTo]);

  return isAuthenticated ? <>{children}</> : <Loader />;
};

export default LoginWrapper;
