import Video from "twilio-video";
import { Container, Link, Typography, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    marginTop: "2.5em"
  },
  paper: {
    padding: "1em"
  },
  heading: {
    marginBottom: "0.4em"
  }
});

export default function UnsupportedBrowserWarning({ children }: { children: React.ReactElement }) {
  const classes = useStyles();

  if (!Video.isSupported) {
    return (
      <Container>
        <Grid container justifyContent="center" className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Typography variant="h4" className={classes.heading}>
                We're sorry, we can't connect you on this specific browser.
              </Typography>
              <Typography>
                To deliver you a strong, secure call experience we need to use a modern browser such as Chrome, Safari
                or Edge. If you have another browser installed please try that, otherwise{" "}
                <Link
                  href="https://tacklit.helpscoutdocs.com/article/365-telehealth-set-up"
                  target="_blank"
                  rel="noreferrer"
                >
                  please see our step by step set up guide.
                </Link>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children;
}
