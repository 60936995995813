import { useEffect, useState } from "react";
import { Call, Device } from "@twilio/voice-sdk";
import OnCall from "./OnCall";
import Dialler from "./Dialler";
import { makeStyles } from "@material-ui/core";
import InitiateDialog from "./InitiateDialog";
import CallConnection, { CallState } from "./CallConnection";
import { useAppState } from "../../state";
import ConnectingCall from "./ConnectingCall";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20
  },
  loaderWrapper: {
    width: "100%",
    height: "100%"
  }
}));

interface PhoneProps {
  token: string;
  phoneNumber?: string;
  firstName?: string;
}
const Phone = ({ token, phoneNumber, firstName }: PhoneProps) => {
  const styles = useStyles();
  const { setError, setSuccess } = useAppState();
  const [state, setState] = useState<CallState>(CallState.OFFLINE);
  const [device, setDevice] = useState<Device | null>(null);
  const [call, setCall] = useState<Call | null>(null);
  const [number, setNumber] = useState(phoneNumber ?? "+6");
  const [toDisplayName, setToDisplayName] = useState(!!phoneNumber && !!firstName);
  const [incomingCall, setIncomingCall] = useState<Call | null>(null);

  useEffect(() => {
    if (number === phoneNumber && firstName && !toDisplayName) {
      setToDisplayName(true);
    }
    if (number !== phoneNumber && toDisplayName) {
      setToDisplayName(false);
    }
    // eslint-disable-next-line
  }, [firstName, phoneNumber, number]);

  const displayName = () => (firstName && number === phoneNumber ? firstName : number);

  const initializeDevice = () => {
    const device = new Device(token, {
      ...(process.env.NODE_ENV === "development" && { logLevel: 1 }),
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU]
    });

    device.on("registered", function () {
      setState(CallState.READY);
    });

    device.on("error", function (error) {
      setError({
        code: 400,
        name: "Device Error",
        message: "There's an error when trying to start up the device"
      });
      setState(CallState.OFFLINE);
    });

    device.register();

    setDevice(device);
  };

  const handleCall = async () => {
    if (!device) {
      return;
    }

    const params = { To: number };
    setState(CallState.CONNECTING);

    const call = await device.connect({ params }).catch((err) => {
      setState(CallState.READY);
      setError({
        code: 400,
        name: "Call Error",
        message: "There's an error when trying to call"
      });
    });
    if (!call) {
      return;
    }
    setCall(call);

    call.on("accept", (call: Call) => {
      setState(CallState.ON_CALL);
      setCall(call);
    });
    call.on("disconnect", () => {
      setState(CallState.READY);
      setCall(null);
      setSuccess({
        name: "Call Disconnected",
        message: "Call has been disconnected"
      });
    });
    call.on("cancel", () => {
      setState(CallState.READY);
      setCall(null);
      setSuccess({
        name: "Call Cancelled",
        message: "Call has been cancelled"
      });
    });

    call.on("error", () => {
      setState(CallState.READY);
      setError({
        code: 400,
        name: "Call Error",
        message: "There's an error when trying to call"
      });
    });
  };

  const handleHangup = () => {
    if (incomingCall) {
      incomingCall.reject();
      setIncomingCall(null);
    }

    if (call) {
      call.disconnect();
      setCall(null);
    }
    setState(CallState.READY);
  };

  let render;
  if (call) {
    if (state === CallState.ON_CALL) {
      render = <OnCall handleHangup={handleHangup} connection={call} displayName={displayName()} />;
    } else if (state === CallState.CONNECTING) {
      render = <ConnectingCall displayName={displayName()} handleHangup={handleHangup} />;
    }
  } else {
    render = (
      <Dialler
        disabled={state === CallState.OFFLINE}
        number={number}
        setNumber={setNumber}
        firstName={firstName}
        displayName={toDisplayName}
        handleCall={handleCall}
      />
    );
  }

  return (
    <div className={styles.container}>
      <CallConnection state={state} />
      <InitiateDialog initializeDevice={initializeDevice} />
      {render}
    </div>
  );
};

export default Phone;
