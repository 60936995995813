import axios from "axios";
import { ClinicianMedicareRole } from "../components/ProcessAppointmentForm/mbsItems";
import { IamProviderAndTenantDomain } from "../utils/auth0";

const clinicianProfileServiceApi = axios.create({
  baseURL: process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL!,
  headers: {
    "Content-Type": "application/json"
  },
  validateStatus: (status) => status < 400
});

export enum CustomFeature {
  ProcessAppointment = "processAppointment",
  InvoiceListV2 = "invoiceListV2",
  Raise = "raise"
}

export interface Practice {
  name: string;
}
export interface UserProfile {
  /**
   * currently for data needed only
   */
  name: string;
  avatar: string;
  _id: string;
  accountId: string;
  medicare: { role: ClinicianMedicareRole };
  practice?: Practice;
  customFeatures?: CustomFeature[];
}

export const getProfile = async (token: string) =>
  clinicianProfileServiceApi.get<UserProfile>("/profile", {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const getClinicianProfileById = async (token: string, clinicianId: string) =>
  clinicianProfileServiceApi.get<UserProfile>(`/clinician/${clinicianId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

export const checkIamProviderAndTenantDomain = async (email: string) =>
  clinicianProfileServiceApi.post<IamProviderAndTenantDomain>("/users/me/login", { email });
