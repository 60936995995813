import { Button, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { WaitroomParticipant } from "../../../services/schedService";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid #E4E7E9",
    padding: 10
  },
  buttonContainer: {
    display: "flex",
    gap: 2
  }
}));

interface WaitingRoomParticipantProps {
  participant: WaitroomParticipant;
  onAdmit: (participantId: string) => Promise<void>;
  onReject: (participantId: string) => Promise<void>;
}

const WaitingRoomParticipant = ({ participant, onAdmit, onReject }: WaitingRoomParticipantProps) => {
  const classes = useStyles();

  const [isAdmitting, setIsAdmitting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const disabled = isAdmitting || isRejecting;

  const handleAdmit = async () => {
    setIsAdmitting(true);

    await onAdmit(participant.participantId);

    setIsAdmitting(false);
  };

  const handleReject = async () => {
    setIsRejecting(true);

    await onReject(participant.participantId);

    setIsRejecting(false);
  };

  return (
    <div className={classes.container}>
      <p>{participant.name}</p>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" disabled={disabled || participant.isAdmitted} onClick={handleAdmit}>
          {isAdmitting ? "Admitting" : participant.isAdmitted ? "Connecting" : "Admit"}
        </Button>
        <Button variant="contained" color="secondary" disabled={disabled} onClick={handleReject}>
          {isRejecting ? "Rejecting" : "Reject"}
        </Button>
      </div>
    </div>
  );
};

export default WaitingRoomParticipant;
