import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import clsx from "clsx";

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      transform: "none !important",
      borderRadius: "2px !important"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "16px"
    },
    message: {
      display: "flex",
      flexDirection: "column",
      gap: "23px",
      paddingLeft: "20px"
    },
    messageContent: {
      display: "flex",
      flexDirection: "column",
      gap: "7px"
    },
    basicDetails: {
      display: "flex",
      padding: "21px 20px",
      borderRadius: "4px",
      background: "#F5F6FF",
      gap: "23px",
      alignItems: "center"
    },
    moreDetails: {
      display: "flex",
      padding: "14px 20px",
      borderRadius: "4px",
      gap: "40px",
      alignItems: "center",
      border: "1px solid #DADBDE"
    },
    itemContainer: {
      borderBottom: "1px solid #E6E6E6",
      paddingBottom: "4px",
      display: "flex",
      flexDirection: "column",
      gap: "4px"
    },
    secondRow: {
      display: "flex",
      gap: "20px",
      alignItems: "center"
    },
    invoice: {
      display: "flex",
      alignItems: "center",
      gap: "80px",
      padding: "12px 20px"
    },
    createInvoice: {
      display: "flex",
      alignItems: "center",
      gap: "8px"
    },
    invoiceStatus: {
      display: "flex",
      height: "23px",
      padding: "4.5px 8px 4.5px 13px",
      alignItems: "center",
      gap: "13px",
      borderRadius: "43px",
      background: "#EDF9F2"
    },
    claim: {
      display: "flex",
      gap: "16px",
      padding: "16px 20px",
      border: "1px solid #DADBDE",
      borderRadius: "4px"
    },
    actions: {
      display: "flex",
      gap: "16px",
      justifyContent: "flex-end",
      alignItems: "flex-end"
    },
    submitButton: {
      height: "48px",
      width: "163px",
      padding: "18px",
      borderRadius: "4px",
      background: "linear-gradient(234deg, #988337 -10.24%, #C5B000 106.12%)"
    },
    buttonLabel: {
      backgroundColor: "#E6E6E6 !important"
    }
  })
);

const LoadingForm = () => {
  const classes = useStyles();

  const renderMoreDetailsItem = (index: number) => (
    <div className={classes.itemContainer} key={index}>
      <Skeleton className={classes.text} height={10} width={90} />
      <div className={classes.secondRow}>
        <Skeleton className={classes.text} height={14} width={120} />
        <Skeleton className={classes.text} height={5} width={10} />
        {index === 0 && <Skeleton variant="circular" width={20} height={20} />}
      </div>
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.message}>
        <Skeleton className={classes.text} height={21} width={337} />
        <div className={classes.messageContent}>
          <Skeleton className={classes.text} height={16} width={649} />
          <Skeleton className={classes.text} height={16} width={726} />
          <Skeleton className={classes.text} height={16} width={465} />
        </div>
      </div>

      <div className={classes.basicDetails}>
        <Skeleton className={classes.text} height={14} width={100} />
        <Divider orientation="vertical" flexItem={true} />
        <Skeleton className={classes.text} height={14} width={100} />
        <Skeleton className={classes.text} height={5} width={10} />
        <Divider orientation="vertical" flexItem={true} />
        <Skeleton className={classes.text} height={14} width={100} />
        <Skeleton className={classes.text} height={5} width={10} />
      </div>

      <div className={classes.moreDetails}>{[...Array(3)].map((_, i) => renderMoreDetailsItem(i))}</div>
      <div className={classes.invoice}>
        <div className={classes.createInvoice}>
          <Skeleton variant="rectangular" height={22} width={22} />
          <Skeleton className={classes.text} height={14} width={58} />
        </div>
        <div className={classes.invoiceStatus}>
          <Skeleton className={classes.text} height={9} width={95} />
          <Skeleton className={classes.text} height={5} width={10} />
        </div>
      </div>
      <div className={classes.claim}>
        <Skeleton className={classes.text} height={14} width={100} />
        <Divider orientation="vertical" flexItem={true} />
        <Skeleton className={classes.text} height={14} width={100} />
      </div>
      <div className={classes.actions}>
        <Skeleton className={classes.text} height={10.5} width={277} />
        <div className={classes.submitButton}>
          <Skeleton className={clsx(classes.text, classes.buttonLabel)} height={14} width={80} />
        </div>
      </div>
    </div>
  );
};

export default LoadingForm;
