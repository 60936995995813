import { CircularProgress, makeStyles } from "@material-ui/core";
import Background from "../Background/Background";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    flex: "1"
  },
  innerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "95vw",
    maxWidth: "1200px",
    minHeight: "379px",
    borderRadius: "8px",
    boxShadow: "4px 4px 8px 4px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      width: "calc(100% - 40px)",
      height: "fit-content",
      margin: "55px auto 20px auto",
      maxWidth: "400px"
    }
  },
  loaderContainer: {
    height: "100%",
    padding: "20px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      minHeight: "379px"
    }
  }
}));

export const LoaderIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.loaderContainer}>
      <CircularProgress size={30} />
    </div>
  );
};

const Loader = () => {
  const classes = useStyles();
  return (
    <Background>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <LoaderIcon />
        </div>
      </div>
    </Background>
  );
};

export default Loader;
