import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CheckCircleOutline } from "@material-ui/icons";
import clsx from "clsx";
import { useRef, useState } from "react";
import { postClientRequestHelp, postRequestHelp } from "../../../services/schedService";
import { useAppState } from "../../../state";
import { useVideoContext } from "../../VideoProvider";

enum AskForHelpButtonStatus {
  Default = "default",
  Confirm = "confirm",
  Loading = "loading",
  Success = "success"
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaultButton: {
      background: theme.brand,
      color: "white",
      "&:hover": {
        background: "#600101"
      }
    },
    confirmButton: {
      background: "#FFE5E5",
      color: "black",
      border: "1px solid #FE0404",
      "&:hover": {
        background: "#D54141",
        color: "white"
      }
    },
    succcesButton: {
      background: "#F5F6FF",
      color: "#00109F",
      border: "1px solid #25BE69"
    },
    successIcon: {
      color: "#25BE69"
    }
  })
);

const AskForHelpButton = (props: { className?: string }) => {
  const classes = useStyles();
  const [buttonStatus, setButtonStatus] = useState<AskForHelpButtonStatus>(AskForHelpButtonStatus.Default);

  const handlerConfirm = useRef<NodeJS.Timeout | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useAppState();
  const { room, userParticipant } = useVideoContext();

  const handleConfirm = () => {
    setButtonStatus(AskForHelpButtonStatus.Confirm);

    handlerConfirm.current = setTimeout(() => {
      setButtonStatus(AskForHelpButtonStatus.Default);
    }, 5000);
  };

  const handleSubmit = async () => {
    if (!room) return;

    if (handlerConfirm.current) {
      clearTimeout(handlerConfirm.current);
    }

    setButtonStatus(AskForHelpButtonStatus.Loading);

    const token: string = user?.isClinician ? await getAccessTokenSilently() : "";

    try {
      token
        ? await postRequestHelp(token, room.name, {
            senderId: user?.sub,
            name: user?.name || userParticipant?.name // userParticipant should exist here at least
          })
        : await postClientRequestHelp(room.name, {
            senderId: user?._id,
            name: user?.name || userParticipant?.name // userParticipant should exist here at least
          });
      setButtonStatus(AskForHelpButtonStatus.Success);

      setTimeout(() => setButtonStatus(AskForHelpButtonStatus.Default), 10000);
    } catch (ex) {
      console.error(ex);
      setButtonStatus(AskForHelpButtonStatus.Default);
    }
  };

  return (
    <>
      {buttonStatus === AskForHelpButtonStatus.Default && (
        <Button
          onClick={handleConfirm}
          disabled={!room}
          className={clsx(classes.defaultButton, props.className)}
          data-cy-disconnect
        >
          Ask for help
        </Button>
      )}

      {buttonStatus === AskForHelpButtonStatus.Confirm && (
        <Button onClick={handleSubmit} className={clsx(classes.confirmButton, props.className)} data-cy-disconnect>
          Click to confirm
        </Button>
      )}

      {buttonStatus === AskForHelpButtonStatus.Loading && (
        <Button className={clsx(classes.confirmButton, props.className)} data-cy-disconnect disabled>
          Loading...
        </Button>
      )}

      {buttonStatus === AskForHelpButtonStatus.Success && (
        <Button
          startIcon={<CheckCircleOutline className={classes.successIcon} />}
          onClick={() => setButtonStatus(AskForHelpButtonStatus.Default)}
          className={clsx(classes.succcesButton, props.className)}
          data-cy-disconnect
        >
          Request sent
        </Button>
      )}
    </>
  );
};

export default AskForHelpButton;
