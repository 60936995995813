const CheckMark = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9.7103 12.7047L13.7062 8.71128C13.8943 8.52328 14 8.26831 14 8.00244C14 7.73657 13.8943 7.4816 13.7062 7.29361C13.5181 7.10561 13.263 7 12.9969 7C12.7309 7 12.4758 7.10561 12.2877 7.29361L9.00103 10.5882L7.71234 9.29032C7.52423 9.10232 7.2691 8.99672 7.00307 8.99672C6.73703 8.99672 6.4819 9.10232 6.2938 9.29032C6.10568 9.47831 6 9.73328 6 9.99915C6 10.265 6.10568 10.52 6.2938 10.708L8.29175 12.7047C8.38462 12.7983 8.4951 12.8725 8.61684 12.9232C8.73857 12.9739 8.86914 13 9.00103 13C9.1329 13 9.26347 12.9739 9.3852 12.9232C9.50694 12.8725 9.61742 12.7983 9.7103 12.7047Z"
        fill="#0E7C3A"
      />
    </svg>
  );
};

export default CheckMark;
