import { useMediaQuery } from "@material-ui/core";
import { BreakpointsOptions } from "@material-ui/core/styles/createBreakpoints";
import { ReactNode } from "react";

// TODO: extends
interface HiddenProps {
  children: ReactNode;
  // Only one of these options allowed to be passed
  smDown?: boolean;
  mdUp?: boolean;
}
const Hidden = ({ children, smDown, mdUp }: HiddenProps) => {
  // @ts-ignore
  const hidden = useMediaQuery((theme) => {
    // @ts-ignore
    const breakpoints = theme.breakpoints as BreakpointsOptions;
    if (mdUp) {
      return breakpoints.up?.("md");
    } else if (smDown) {
      return breakpoints.down?.("sm");
    }
  });

  return <>{hidden ? null : children}</>;
};

export default Hidden;
