import {
  ClinicianMedicareRole,
  MedicareItem,
  MedicareItemDuration,
  MedicareItemFormat,
  MedicareItemMode
} from "./interfaces";

export const CLINICAL_PSYCHOLOGIST_ITEMS: MedicareItem[] = [
  {
    mbsCode: "80000",
    description:
      "Initial individual face to face (consultation rooms) session by a clinical psychologist, lasting more than 30 minutes but less than 50 minutes.",
    benefit: 96.65,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "80005",
    description:
      "Initial individual face to face (call-out) session by a clinical psychologist, lasting more than 30 minutes but less than 50 minutes.",
    benefit: 120.75,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "80001",
    description:
      "Initial individual telehealth (geographic eligibiility applies) session by a clinical psychologist, lasting more than 30 minutes but less than 50 minutes.",
    benefit: 88.25,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: "91166",
    description:
      "Initial individual telehealth (video call) session by a clinical psychologist, lasting more than 30 minutes but less than 50 minutes.",
    benefit: 96.65,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "91181",
    description:
      "Initial individual telehealth (phone call) session by a clinical psychologist, lasting more than 30 minutes but less than 50 minutes.",
    benefit: 96.65,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "80010",
    description:
      "Initial individual face to face (consultation rooms) session by a clinical psychologist, lasting at least 50 minutes.",
    benefit: 141.85,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: "80015",
    description:
      "Initial individual face to face (call-out) session by a clinical psychologist, lasting at least 50 minutes.",
    benefit: 165.95,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: "80011",
    description:
      "Initial individual telehealth (geographic eligibiility applies) session by a clinical psychologist, lasting at least 50 minutes.",
    benefit: 129.55,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: "91167",
    description:
      "Psychological therapy health service provided by telehealth attendance by an eligible clinical psychologist, lasting at least 50 minutes.",
    benefit: 141.85,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: "91182",
    description:
      "Initial individual telehealth (phone call) session by a clinical psychologist, lasting at least 50 minutes.",
    benefit: 141.85,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: "91171",
    description:
      "Telehealth psychological therapy health service provided by an eligible clinical psychologist, lasting at least 50 minutes, to a person other than the patient, provided the service is part of the patient’s treatment.",
    benefit: 141.85,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: "91199",
    description:
      "Phone psychological therapy health service provided by an eligible clinical psychologist, lasting at least 50 minutes, to a person other than the patient, provided the service is part of the patient’s treatment.",
    benefit: 141.85,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: "93330",
    description:
      "Additional individual face to face (consultation rooms) session by a clinical psychologist, lasting more than 30 minutes but less than 50 minutes.",
    benefit: 88.25,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: "93331",
    description:
      "Additional individual telehealth (video call) session by a clinical psychologist, lasting more than 30 minutes but less than 50 minutes.",
    benefit: 88.25,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: "93332",
    description:
      "Additional individual telehealth (phone call) session by a clinical psychologist, lasting more than 30 minutes but less than 50 minutes.",
    benefit: 88.25,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50,
    disabled: true
  },
  {
    mbsCode: "93333",
    description:
      "Additional individual face to face (consultation rooms) session by a clinical psychologist, lasting at least 50 minutes.",
    benefit: 129.55,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: "93334",
    description:
      "Additional individual telehealth (video call) session by a clinical psychologist, lasting at least 50 minutes.",
    benefit: 129.55,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: "93335",
    description:
      "Additional individual telehealth (phone call) session by a clinical psychologist, lasting at least 50 minutes.",
    benefit: 129.55,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50,
    disabled: true
  },
  {
    mbsCode: "80020",
    description: "Group face to face session by a clinical psychologist, lasting at least 60 minutes.",
    benefit: 36,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: "80021",
    description:
      "Group telehealth (geographic eligibility applies) session by a clinical psychologist, lasting at least 60 minutes.",
    benefit: 36,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: "82355",
    description:
      "Eating disorder psychological treatment service provided to an eligible patient in consulting rooms by an eligible clinical psychologist, lasting at least 50 minutes.",
    benefit: 141.85,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: "82357",
    description:
      "Eating disorder psychological treatment service provided to an eligible patient at a place other than consulting rooms by an eligible clinical psychologist, lasting at least 50 minutes.",
    benefit: 165.95,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: "82358",
    description:
      "Eating disorder psychological treatment service provided to an eligible patient in person as part of a group of 6 to 10 patients by an eligible clinical psychologist, lasting at least 60 minutes.",
    benefit: 36,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.FaceToFace,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: "82359",
    description:
      "Eating disorder psychological treatment service provided to an eligible patient by video conference as part of a group of 6 to 10 patients by an eligible clinical psychologist, lasting at least 60 minutes.",
    benefit: 36,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.Group,
    mode: MedicareItemMode.VideoCall,
    duration: MedicareItemDuration.GroupMoreThan60
  },
  {
    mbsCode: "93076",
    description:
      "Eating disorder psychological treatment service provided by telehealth attendance to an eligible patient by an eligible clinical psychologist, lasting at least 30 minutes but less than 50 minutes.",
    benefit: 96.65,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "93079",
    description:
      "Eating disorder psychological treatment service provided by telehealth attendance to an eligible patient by an eligible clinical psychologist, lasting at least 50 minutes.",
    benefit: 141.85,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.Telehealth,
    duration: MedicareItemDuration.OneToOneMoreThan50
  },
  {
    mbsCode: "93110",
    description:
      "Eating disorder psychological treatment service provided by phone attendance to an eligible patient by an eligible clinical psychologist, lasting at least 30 minutes but less than 50 minutes.",
    benefit: 96.65,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneLessThan50
  },
  {
    mbsCode: "93113",
    description:
      "Eating disorder psychological treatment service provided by phone attendance to an eligible patient by an eligible clinical psychologist, lasting at least 50 minutes.",
    benefit: 141.85,
    role: ClinicianMedicareRole.ClinicalPsychologists,
    format: MedicareItemFormat.OneToOne,
    mode: MedicareItemMode.PhoneCall,
    duration: MedicareItemDuration.OneToOneMoreThan50
  }
];
