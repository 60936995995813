import { useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useAppState } from "../../../state";
import { useAuth0 } from "@auth0/auth0-react";
import { endRoom } from "../../../services/schedService";
import AlertDialog from "../../AlertDialog/AlertDialog";
import { useVideoContext } from "../../VideoProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomFeature } from "../../../services/clinicianProfileService";
import { useFetchClinicianProfileById } from "../../../hooks/useGetClinicianProfileById";
import { ClaimType } from "../../ProcessAppointmentForm/helpers";
import queryString from "query-string";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: "white",
      "&:hover": {
        background: "#600101"
      }
    }
  })
);

export const LeaveRoomButton = (props: { className?: string; roomName: string }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const { accountSettings, user } = useAppState();
  const { leaveRoom } = useVideoContext();

  const [openAlert, setOpenAlert] = useState(false);

  const handleLeave = () => {
    leaveRoom();
    setOpenAlert(false);

    const parsedSearch = queryString.parse(location.search);

    if (accountSettings?.postSessionFormId && !user?.isClinician && parsedSearch.skipForms !== "1") {
      navigate(`/room/${props.roomName}/survey/${accountSettings.postSessionFormId}`);
    }
  };

  return (
    <>
      <Button onClick={() => setOpenAlert(true)} className={clsx(classes.button, props.className)} data-cy-disconnect>
        Leave
      </Button>
      <AlertDialog
        title="Leave Room"
        desc="Are you sure you want to leave this room?"
        open={openAlert}
        onCancel={() => setOpenAlert(false)}
        onOK={() => handleLeave()}
      />
    </>
  );
};

export const EndRoomButton = (props: { className?: string }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user, appointment } = useAppState();
  const { getAccessTokenSilently } = useAuth0();
  const { room, leaveRoom } = useVideoContext();
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const allowToEndRoom = !!user?.isClinician && !user.joinAsParticipant && room;

  const { isLoading: isClinicianLoading, clinician } = useFetchClinicianProfileById(appointment?.clinicianId);

  const handleEnd = async () => {
    if (!allowToEndRoom) {
      return;
    }
    setOpenAlert(false);
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    await endRoom(token, room.name);
    leaveRoom();
    setIsLoading(false);

    const canProcessAppointment =
      appointment?._id &&
      !appointment.isProcessed &&
      !appointment.requestedChanges &&
      clinician?.customFeatures?.includes(CustomFeature.ProcessAppointment);

    const isSpecialAppointment =
      !appointment?.claimType || ![ClaimType.BULK_BILL, ClaimType.REBATE].includes(appointment.claimType);

    if (
      appointment &&
      canProcessAppointment &&
      (isSpecialAppointment ? !!clinician?.customFeatures?.includes(CustomFeature.InvoiceListV2) : true)
    ) {
      navigate(`/clinicians/appointments/${appointment._id}/process`);
    }
  };

  if (!allowToEndRoom) {
    return null;
  }

  return (
    <>
      <Button onClick={() => setOpenAlert(true)} className={clsx(classes.button, props.className)} data-cy-disconnect>
        {isLoading || isClinicianLoading ? "Loading..." : "End Room"}
      </Button>
      <AlertDialog
        title="End Room"
        desc="Are you sure you want to end this room? All participants will be disconnected"
        open={openAlert}
        onCancel={() => setOpenAlert(false)}
        onOK={() => handleEnd()}
      />
    </>
  );
};
