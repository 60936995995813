import TacklitSecureLight from "../../images/tacklit/tacklitSecure.svg";
import TacklitSecureDark from "../../images/tacklit/tacklitSecureInverse.svg";

interface Props {
  isDark?: boolean;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
}
const TacklitSecureLogo = ({ isDark = false, containerProps, imgProps }: Props) => {
  return (
    <div {...containerProps}>
      <img src={isDark ? TacklitSecureDark : TacklitSecureLight} alt="tacklit-secure" {...imgProps} />
    </div>
  );
};

export default TacklitSecureLogo;
