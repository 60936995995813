import { Route, Routes } from "react-router-dom";
import { VoiceCallPage } from "../pages/VoiceCall";

const PhoneCallRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<VoiceCallPage />} />
      <Route path="/:appointmentId" element={<VoiceCallPage />} />
    </Routes>
  );
};

export default PhoneCallRoutes;
