import { useCallback, useState } from "react";

const useOpen = () => {
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return { open, toggleOpen };
};

export default useOpen;
