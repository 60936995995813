import { Button, FormControl, MenuItem, Typography, Select, makeStyles } from "@material-ui/core";
import CloseIcon from "../../../../icons/CloseIcon";
import { useVideoContext } from "../../../VideoProvider";
import { useAppState } from "../../../../state";
import { Pause, PlayArrow } from "@material-ui/icons";
import SpeakersTestAudio from "../../../../assets/SpeakersTestAudio.mp3";
import { useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "300px",
    maxWidth: "300px",
    minWidth: "240px",
    padding: "8px 12px",
    background: "#FFF",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.10)",
    overflow: "hidden",
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    top: "8px",
    right: "12px",
    minWidth: 0,
    padding: 0
  },
  overlayTitle: {
    maxWidth: "calc(100% - 30px)",
    marginBottom: "8px",
    color: "#414449",
    fontSize: "12px",
    fontWeight: 600
  },
  selectContainer: {
    width: "100%",
    marginBottom: "12px"
  },
  testButton: {
    width: "100px",
    fontSize: "12px",
    fontWeight: 600,
    color: "#3F52FF",
    borderColor: "#3F52FF"
  }
}));

interface SpeakerSettingsProps {
  onClose?: () => void;
}

const SpeakerSettings = ({ onClose }: SpeakerSettingsProps) => {
  const classes = useStyles();

  const { audioOutputDevices } = useVideoContext();
  const { activeSinkId, setActiveSinkId } = useAppState();

  const testSpeakerRef = useRef<HTMLAudioElement | null>(null);
  const [isPlayingTest, setIsPlayingTest] = useState(false);

  const handlePlayTest = () => {
    if (testSpeakerRef.current) {
      if (isPlayingTest) {
        testSpeakerRef.current.pause();
        testSpeakerRef.current.currentTime = 0;
      } else {
        testSpeakerRef.current.muted = false;
        testSpeakerRef.current.play();
      }

      setIsPlayingTest(!isPlayingTest);
    }
  };

  return (
    <div className={classes.container}>
      <Button className={classes.closeButton} variant="text" onClick={onClose}>
        <CloseIcon size={16} color="#414449" stroke strokeWidth="2" />
      </Button>
      <div className={classes.overlayTitle}>Speakers</div>
      <div className={classes.selectContainer}>
        {audioOutputDevices.length > 0 ? (
          <FormControl fullWidth>
            <Select
              value={activeSinkId}
              variant="outlined"
              onChange={(e) => setActiveSinkId(e.target.value as string)}
              onMouseDown={(e) => e.stopPropagation()}
            >
              {audioOutputDevices.map((device) => (
                <MenuItem value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Typography>{"System Default Audio Output"}</Typography>
        )}
      </div>
      <Button
        className={classes.testButton}
        variant="outlined"
        startIcon={isPlayingTest ? <Pause /> : <PlayArrow />}
        onClick={handlePlayTest}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {isPlayingTest ? "Pause" : "Test"}
      </Button>
      <audio src={SpeakersTestAudio} ref={testSpeakerRef} loop />
    </div>
  );
};

export default SpeakerSettings;
