import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

interface SuccessDialogProps {
  dismiss: Function;
  successContent: {
    name: string;
    message: string;
  } | null;
  allowProcessAppointment?: boolean;
  appointmentId?: string;
}

const SuccessDialog = ({ dismiss, successContent, allowProcessAppointment, appointmentId }: SuccessDialogProps) => {
  const navigate = useNavigate();
  return (
    <Dialog open={successContent !== null} onClose={() => dismiss()} fullWidth={true} maxWidth="xs">
      <DialogTitle>{successContent?.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>{successContent?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismiss()} color="primary">
          OK
        </Button>
        {allowProcessAppointment && (
          <Button onClick={() => navigate(`/clinicians/appointments/${appointmentId}/process`)} color="primary">
            Process Appointment
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
