import { DVAItem, MedicareItemDuration, MedicareItemMode } from "../mbsItems/interfaces";

// DVA reference: https://www.dva.gov.au/sites/default/files/2024-07/psychologistfees-1-jul-2024.pdf
export const DVA_VIDEO_CALL_MORE_THAN_20: DVAItem = {
  code: "US19",
  description: "Consultation 20-50 Minutes - Video Conference",
  benefit: 112.95,
  duration: MedicareItemDuration.OneToOneMoreThan20,
  mode: MedicareItemMode.VideoCall
};
export const DVA_VIDEO_CALL_MORE_THAN_50: DVAItem = {
  code: "US20",
  description: "Consultation 50+ Minutes - Video Conference",
  benefit: 159.55,
  duration: MedicareItemDuration.OneToOneMoreThan50,
  mode: MedicareItemMode.VideoCall
};
export const DVA_PHONE_CALL_MORE_THAN_20: DVAItem = {
  code: "US41",
  description: "Consultation 20-50 Minutes - Phone Consultation",
  benefit: 112.95,
  duration: MedicareItemDuration.OneToOneMoreThan20,
  mode: MedicareItemMode.PhoneCall
};
export const DVA_PHONE_CALL_MORE_THAN_50: DVAItem = {
  code: "US42",
  description: "Consultation 50+ Minutes - Phone Consultation",
  benefit: 159.55,
  duration: MedicareItemDuration.OneToOneMoreThan50,
  mode: MedicareItemMode.PhoneCall
};
