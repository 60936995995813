import { Call } from "@twilio/voice-sdk";
import useLoudness from "../../hooks/voiceCallHooks/useLoudness";
import useMuteWarning from "../../hooks/voiceCallHooks/useMuteWarning";
import { Button, makeStyles } from "@material-ui/core";
import PersonIcon from "@mui/icons-material/Person";
// import VolumeOffIcon from "@mui/icons-material/VolumeOff";
// import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import CallEndIcon from "@mui/icons-material/CallEnd";

const useStyles = makeStyles(() => ({
  iconContainer: {
    width: 80,
    height: 80,
    borderRadius: "50%",
    border: "1px solid #cbd5e1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  icon: {
    color: "#64748b"
  },
  call: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10
  },
  buttonIcon: {
    marginRight: 5
  }
}));

interface OnCallProps {
  handleHangup: () => void;
  connection: Call;
  displayName: string;
}

const OnCall = ({ handleHangup, displayName }: OnCallProps) => {
  const styles = useStyles();

  // const [muted, setMuted] = useState(false);
  const {
    running,
    // setRunning,
    loudness
  } = useLoudness();
  const [showMuteWarning] = useMuteWarning(loudness, running);

  // const handleMute = () => {
  //   connection.mute(!muted);
  //   setMuted(!muted);
  //   setRunning(!muted);
  // };

  const muteWarning = <p>Are you speaking? You are on mute!</p>;

  return (
    <>
      {showMuteWarning && muteWarning}
      <div className={styles.iconContainer}>
        <PersonIcon fontSize="large" className={styles.icon} />
      </div>
      <p>{displayName}</p>
      <div className={styles.call}>
        {/* <Button variant="contained" onClick={handleMute} color="primary">
          {muted ? (
            <>
              <VolumeUpIcon className={styles.buttonIcon} />
              Unmute
            </>
          ) : (
            <>
              <VolumeOffIcon className={styles.buttonIcon} />
              Mute
            </>
          )}
        </Button> */}
        <Button variant="contained" onClick={handleHangup} color="secondary">
          <CallEndIcon className={styles.buttonIcon} />
          Hang up
        </Button>
      </div>
    </>
  );
};

export default OnCall;
