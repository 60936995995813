const ViewIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1066 4.5C13.3588 4.5 16.5838 6.76775 18.5321 8.90985C18.8329 9.2418 18.9997 9.67365 19 10.1216C19.0003 10.5696 18.8342 11.0017 18.5339 11.334C16.5851 13.4788 13.3601 15.7479 10.1068 15.7479H9.87281C6.63682 15.7479 3.41357 13.4788 1.46766 11.3354C1.16696 11.0034 1.00029 10.5717 1 10.1238C0.999716 9.67591 1.16584 9.2439 1.46612 8.91161C3.43553 6.74403 6.71547 4.4631 9.99892 4.50088L10.003 4.50083C10.0375 4.50042 10.0723 4.5 10.1066 4.5ZM10.0908 14.623C12.9941 14.623 15.9153 12.5433 17.6997 10.5792V10.5791C17.8126 10.4535 17.8749 10.2905 17.8747 10.1217C17.8744 9.95277 17.8115 9.78998 17.6982 9.66476C15.9146 7.70369 12.9928 5.62488 10.109 5.62488C10.0933 5.62488 10.0776 5.62512 10.0619 5.62536C10.0409 5.62569 10.0199 5.62601 9.99892 5.62575C9.9775 5.62601 9.95645 5.62568 9.9355 5.62536C9.91992 5.62512 9.9044 5.62488 9.88885 5.62488C7.0035 5.62488 4.08365 7.70368 2.30034 9.66651C2.18743 9.7921 2.1251 9.9551 2.12538 10.124C2.12567 10.2929 2.18855 10.4557 2.30187 10.5809C4.10409 12.5661 7.06479 14.6722 9.98947 14.6221C10.0231 14.6225 10.0569 14.623 10.0908 14.623ZM9.99894 6.7489H10.0009C10.8954 6.74917 11.7531 7.10462 12.3855 7.73711C13.018 8.3696 13.3734 9.22736 13.3735 10.1218V10.1249C13.3722 11.0192 13.0162 11.8765 12.3838 12.5089C11.7513 13.1412 10.8939 13.497 9.9996 13.4982H9.99784C9.10284 13.498 8.24455 13.1423 7.61178 12.5094C6.97902 11.8764 6.62362 11.018 6.62377 10.123C6.62391 9.22797 6.97959 8.36968 7.61256 7.73692C8.24553 7.10416 9.10394 6.74876 9.99894 6.7489ZM11.5891 11.7135C12.0107 11.2919 12.2479 10.7203 12.2487 10.124L12.2487 10.1222C12.2487 9.52593 12.0118 8.95407 11.5902 8.53241C11.1686 8.11076 10.5967 7.87384 10.0005 7.87378H9.99915C9.40248 7.87366 8.8302 8.11057 8.4082 8.53239C7.98621 8.95422 7.74907 9.52641 7.74895 10.1231C7.74883 10.7197 7.98574 11.292 8.40757 11.714C8.82939 12.136 9.40158 12.3732 9.99825 12.3733H9.99957C10.5958 12.3724 11.1674 12.1352 11.5891 11.7135Z"
        fill="#121C2D"
      />
    </svg>
  );
};

export default ViewIcon;
