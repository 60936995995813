import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useAuthValidating = () => {
  const location = useLocation();

  const isRedirecting = useMemo(
    () => location.pathname === "/" && location.search.includes("code"),
    [location.pathname, location.search]
  );

  return { isRedirecting };
};

export default useAuthValidating;
