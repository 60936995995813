import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { Auth0ApplicationConfig, AUTH0_APPLICATION_CONFIGS, generateAuth0ClientOptions } from "./constants";
import Loader from "../../components/Loader/Loader";
import { hasAuthParams } from "./utils";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AppState } from "@auth0/auth0-react";

const defaultApplicationConfig = AUTH0_APPLICATION_CONFIGS[0];

// This function basically replicates the initialisation of Auth0Provider
// https://github.com/auth0/auth0-react/blob/4a76db3de764aaaa52e4a8d376165aa35e4d9c81/src/auth0-provider.tsx#L149
const findApplicationConfig = async (
  auth0Clients: { client: Auth0Client; config: Auth0ApplicationConfig }[],
  navigate: NavigateFunction
) => {
  const testedApplicationConfigPromises = await Promise.allSettled(
    auth0Clients.map(async ({ client, config }) => {
      // hasAuthParams checks for params passed from the redirect after login
      // both handleRedirectCallback and checkSession check for login, but in slightly different ways.
      // https://github.com/auth0/auth0-spa-js/blob/5ca5720fe2c9e5445f3838f112e55130f8e6780d/src/Auth0Client.ts
      const result = await (hasAuthParams() ? client.handleRedirectCallback<AppState>() : client.checkSession());

      // getUser returns a user if logged in, otherwise undefined
      const user = await client.getUser();

      if (!user) {
        // throwing to indicate not logged in
        throw new Error(`User not logged in to ${JSON.stringify(config)}`);
      }

      if (result) {
        // this clears the login params from the URL, allowing the rest of the UI to function
        navigate(result.appState?.returnTo || window.location.pathname);
      }

      return {
        ...config,
        domain: (process.env.REACT_APP_IS_RAW === "1" && config.rawDomain) || config.domain
      };
    })
  );

  return (
    testedApplicationConfigPromises.find(
      (promise): promise is PromiseFulfilledResult<Auth0ApplicationConfig> => promise.status === "fulfilled"
    )?.value || defaultApplicationConfig
  );
};

const Auth0ApplicationConfigContext = createContext<Auth0ApplicationConfig>(defaultApplicationConfig);

interface Auth0ApplicationConfigContextProviderProps {
  children?: ReactNode | undefined;
}

export const Auth0ApplicationConfigContextProvider = ({ children }: Auth0ApplicationConfigContextProviderProps) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const initialised = useRef(false);
  const [applicationConfig, setApplicationConfig] = useState(defaultApplicationConfig);

  // loosely based on auth0-react code
  // https://github.com/auth0/auth0-react/blob/4a76db3de764aaaa52e4a8d376165aa35e4d9c81/src/auth0-provider.tsx#L149
  useEffect(() => {
    if (initialised.current) {
      return;
    }

    initialised.current = true;

    (async () => {
      try {
        const auth0Clients = AUTH0_APPLICATION_CONFIGS.map((config) => ({
          client: new Auth0Client(generateAuth0ClientOptions(config)),
          config
        }));

        const applicationConfig = await findApplicationConfig(auth0Clients, navigate);

        setApplicationConfig(applicationConfig);
        setIsLoading(false);
      } catch (ex) {
        console.error(ex);
      }
    })();
  }, [navigate]);

  return (
    <Auth0ApplicationConfigContext.Provider value={applicationConfig}>
      {isLoading ? <Loader /> : children}
    </Auth0ApplicationConfigContext.Provider>
  );
};

export const useAuth0ApplicationConfigContext = () => {
  const context = useContext(Auth0ApplicationConfigContext);
  if (!context) {
    throw new Error("useAuth0ApplicationConfigContext must be used within Auth0ApplicationConfigContextProvider");
  }
  return context;
};
