import { Button, makeStyles } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import TacklitLogo from "../../assets/icons/tacklit-logo.png";
import { useEffect, useMemo, useState } from "react";
import { string, ValidationError } from "yup";
import { checkIamProviderAndTenantDomain } from "../../services/clinicianProfileService";
import { AxiosError } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/Loader/Loader";
import { loginWithRedirect } from "../../utils/auth0";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    lineHeight: "1.1"
  },
  logo: {
    width: "52px"
  },
  h3: {
    color: "#2d333a",
    fontSize: "24px",
    fontWeight: 400,
    marginTop: "20px",
    marginBottom: "24px"
  },
  h5: {
    color: "#2d333a",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "10px",
    marginBottom: "24px"
  },
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#2a4170",
    height: "100%"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: "900px",
    backgroundColor: "#fff",
    boxShadow: "4px 4px 8px 4px rgba(0, 0, 0, 0.1)",
    position: "relative",
    padding: "20px 35px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  inputContainer: {
    position: "relative"
  },
  input: {
    background: "none",
    outline: "none",
    fontSize: "16px",
    padding: "10px 10px 10px 5px",
    display: "block",
    width: "320px",
    border: "none",
    borderRadius: "0",
    borderBottom: "1px solid #c6c6c6",

    "&:focus": {
      "& ~": {
        "& label": {
          top: "-14px",
          fontSize: "12px",
          color: "#6f7780"
        }
      }
    },
    "&:valid": {
      "& ~": {
        "& label": {
          top: "-14px",
          fontSize: "12px",
          color: "#6f7780"
        }
      }
    }
  },
  label: {
    color: "#6f7780",
    fontSize: "12px",
    fontWeight: "normal",
    position: "absolute",
    pointerEvents: "none",
    top: "-14px",
    transition: "300ms ease all"
  },
  error: {
    color: "#ff4d4f",
    fontSize: "12px",
    lineHeight: 1.5715
  },
  continueButton: {
    backgroundColor: "#2e486e",
    color: "#ffffff",
    padding: "16px",
    fontSize: "16px"
  },
  trouble: {
    textAlign: "right",
    fontSize: "12px",
    color: "#007bad",
    textDecoration: "none",
    lineHeight: "1.5"
  }
}));

const emailSchema = string().email("Please enter a valid email").required("Please enter your email");

const Login = () => {
  const { state } = useLocation();
  const { isLoading, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const returnTo = useMemo(() => {
    if (state && typeof state === "object" && typeof state.returnTo === "string" && !!state.returnTo) {
      return state.returnTo;
    }

    return `/`;
  }, [state]);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate(returnTo);
    }
  }, [isAuthenticated, isLoading, navigate, returnTo]);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      emailSchema.validateSync(email, { strict: true });
      setError("");
    } catch (ex) {
      setError((ex as ValidationError).message || "Please enter a valid email");
      setLoading(false);
      return;
    }

    try {
      const { data } = await checkIamProviderAndTenantDomain(email);
      loginWithRedirect({ iamConfig: data, returnTo, prefilledEmail: email });
    } catch (ex) {
      if (ex instanceof AxiosError) {
        setError(ex.response?.status === 401 ? "Please enter a valid email" : "Something went wrong.");
      }
    }

    setLoading(false);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={classes.background}>
      <form className={classes.container} noValidate onSubmit={(e) => e.preventDefault()}>
        <div className={classes.header}>
          <img className={classes.logo} src={TacklitLogo} alt="tacklit-logo" />
          <div className={classes.h3}>Welcome</div>
          <div className={classes.h5}>SIGN IN</div>
        </div>
        <div className={classes.inputContainer}>
          <input
            id="email"
            name="email"
            type="email"
            className={classes.input}
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label className={classes.label} htmlFor="email">
            Email
          </label>
          {error && <div className={classes.error}>{error}</div>}
        </div>
        <Button
          variant="contained"
          type="submit"
          disabled={loading}
          className={classes.continueButton}
          onClick={handleSubmit}
        >
          Continue
        </Button>
        <div className={classes.trouble}>
          <a href="mailto:contact@tacklit.com" title="Report issue" id="trouble">
            Report issue
          </a>
        </div>
      </form>
    </div>
  );
};

export default Login;
