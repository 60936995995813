import { useEffect, useRef, useState } from "react";
import Snackbar from "./Snackbar/Snackbar";
import { Button } from "@material-ui/core";
import { WaitroomParticipant } from "../services/schedService";
import { useVideoContext } from "./VideoProvider";

const NewJoinRequestNotification = () => {
  const { participantsInWaitroom, setIsAdminControlOpen } = useVideoContext();

  const oldWaitroomParticipantsRef = useRef<WaitroomParticipant[]>([]);
  const [newParticipantName, setNewParticipantName] = useState<string>();

  const handleClick = () => {
    setNewParticipantName(undefined);
    setIsAdminControlOpen(true);
  };

  useEffect(() => {
    const newParticipant = participantsInWaitroom.find(
      ({ participantId, isAdmitted }) =>
        !isAdmitted &&
        !oldWaitroomParticipantsRef.current.some((participant) => participant.participantId === participantId)
    );

    setNewParticipantName(newParticipant?.name);

    oldWaitroomParticipantsRef.current = [
      ...new Set([...oldWaitroomParticipantsRef.current, ...participantsInWaitroom])
    ];
  }, [participantsInWaitroom]);

  return (
    <Snackbar
      variant="info"
      headline={`${newParticipantName || "Someone"} has requested to join`}
      message={
        <Button
          style={{
            margin: "0px 4px"
          }}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          View
        </Button>
      }
      open={!!newParticipantName}
      handleClose={() => setNewParticipantName(undefined)}
    />
  );
};

export default NewJoinRequestNotification;
