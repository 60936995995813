import { Avatar, makeStyles, Theme } from "@material-ui/core";
import { Button } from "@material-ui/core";
import TacklitSecureLogo from "../../IntroContainer/TacklitSecureLogo";
import Footer from "../../Footer/Footer";
import { useAppState } from "../../../state";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useFetchProcessAppointmentData } from "../../ProcessAppointmentForm/hooks";
import { Practitioner } from "../../ProcessAppointmentForm/helpers";
import ProcessAppointmentForm from "../../ProcessAppointmentForm/ProcessAppointmentForm";
import useOpen from "../../ProcessAppointmentForm/useOpen";
import { useState } from "react";
import {
  postProcessAppointment,
  postSubmitForReview,
  ProcessAppointmentPayload,
  SubmitForReviewPayload
} from "../../../services/schedService";
import { useAuth0 } from "@auth0/auth0-react";
import Snackbar from "../../Snackbar/Snackbar";
import { useGetClinicianId } from "../../../hooks/getClinicianId";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      background: "#f9f9fa",
      borderRadius: "8px",
      maxWidth: "820px",
      minHeight: "480px",
      overflow: "hidden",
      padding: "20px"
    },
    fontFamily: "'Nunito Sans', sans-serif"
  },
  container: {
    background: "#F3FBFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    height: "100%",
    padding: "0 16px"
  },
  header: {
    display: "flex",
    width: "100%",
    position: "absolute",
    justifyContent: "space-between",
    padding: "20px 40px 20px 32px",
    [theme.breakpoints.down(780)]: {
      padding: "16px"
    },
    [theme.breakpoints.down(400)]: {
      position: "relative"
    }
  },
  rightContent: {
    display: "flex",
    gap: "40px",
    alignItems: "center",
    [theme.breakpoints.down(780)]: {
      gap: "24px"
    },
    [theme.breakpoints.down(780)]: {
      gap: "8px"
    }
  },
  backToTacklitButton: {
    padding: "12px 16px",
    lineHeight: "24px",
    borderRadius: "4px",
    color: "#3F52FF",
    border: "1px solid #3F52FF",
    height: "fit-content",
    [theme.breakpoints.down(780)]: {
      padding: "12px"
    }
  },
  buttonLabel: {
    marginLeft: "8px",
    [theme.breakpoints.down(780)]: {
      display: "none"
    }
  },
  clinician: {
    display: "flex",
    gap: "12px"
  },
  practiceAvatar: {
    width: "74px",
    height: "74px",
    borderRadius: "50%",
    border: "2px solid #3F52FF",
    [theme.breakpoints.down(400)]: {
      width: "50px",
      height: "50px"
    }
  },
  names: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "8px",
    [theme.breakpoints.down(780)]: {
      display: "none"
    }
  },
  clinicianName: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.1px"
  },
  practiceName: {
    fontSize: "13px",
    fontWeight: "normal",
    lineHeight: "16px",
    letterSpacing: "0.2px"
  },
  contentWrapper: {
    background: "white",
    padding: "28px 20px 14px 20px",
    maxWidth: "922px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "40px"
  },
  avatars: {
    display: "flex",
    gap: "44px",
    alignSelf: "center",
    alignItems: "center",
    [theme.breakpoints.down(780)]: {
      gap: "20px"
    },
    [theme.breakpoints.down(400)]: {
      gap: "5px"
    }
  },
  clipPathGroup: {
    [theme.breakpoints.down(780)]: {
      height: "21px",
      width: "40px"
    }
  },
  clinicianAvatar: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down(780)]: {
      width: "100px",
      height: "100px"
    },
    [theme.breakpoints.down(400)]: {
      width: "70px",
      height: "70px"
    }
  },
  clientAvatar: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down(780)]: {
      width: "100px",
      height: "100px"
    },
    [theme.breakpoints.down(400)]: {
      width: "70px",
      height: "70px"
    }
  },
  clientInitialsAvatar: {
    color: "white",
    backgroundColor: "#F22F46",
    width: "120px",
    height: "120px",
    fontSize: "50px",
    [theme.breakpoints.down(780)]: {
      width: "100px",
      height: "100px",
      fontSize: "30px"
    },
    [theme.breakpoints.down(400)]: {
      width: "70px",
      height: "70px",
      fontSize: "25px"
    }
  },
  message: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    gap: "8px"
  },
  messageTitle: {
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "42px",
    letterSpacing: "-0.56px"
  },
  messageContent: {
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "30px"
  },
  processButton: {
    padding: "12px 16px",
    background: "linear-gradient(54deg, #3F52FF -11.82%, #00109F 111.69%)",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "4px",
    color: "white",
    maxWidth: "fit-content"
  },
  footerWrapper: {
    width: "100%",
    position: "absolute",
    bottom: "0",
    [theme.breakpoints.down(400)]: {
      position: "relative"
    }
  }
}));

const ProcessAppointmentPage = () => {
  const styles = useStyles();
  const { user, appointment, appointmentClinician } = useAppState();
  const { getAccessTokenSilently } = useAuth0();
  const { auth0ClinicianId } = useGetClinicianId();

  const processAppointmentDialog = useOpen();
  const showProcessNotification = useOpen();
  const showSubmitNotification = useOpen();
  const [isProcessedSuccessfully, setIsProcessedSuccessfully] = useState<boolean>(false);
  const [isSubmitSuccessfully, setIsSubmitSuccessfully] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const selectedPractitioner: Practitioner = {
    accountId: appointmentClinician?.accountId || "",
    _id: appointmentClinician?._id || "",
    medicare: appointmentClinician?.medicare || undefined,
    name: appointmentClinician?.name || ""
  };

  const { isListDataLoading, appointmentTypes, providers } = useFetchProcessAppointmentData({
    accountId: selectedPractitioner?.accountId || "",
    clinicianId: selectedPractitioner?._id || "",
    appointmentId: appointment?._id || "",
    participantType: appointment?.participantType
  });

  const handleBackToTacklit = () => {
    if (appointment?._id) {
      window.location.href = `${process.env.REACT_APP_CLINICIAN_UI_URL}/user/calendar${
        appointment.clinicianId === auth0ClinicianId
          ? `?appointmentId=${appointment?._id}&serviceDelivered=attended`
          : ""
      }`;
    }
  };

  const goToTacklitCalendar = () => {
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_CLINICIAN_UI_URL}/user/calendar`;
    }, 3000);
  };

  const onProcess = async (payload: ProcessAppointmentPayload) => {
    setIsSubmitting(true);
    const token: string = await getAccessTokenSilently();
    try {
      await postProcessAppointment(token, appointment?._id, appointmentClinician?._id, payload);
      processAppointmentDialog.toggleOpen();
      setIsProcessedSuccessfully(true);
      goToTacklitCalendar();
    } catch (ex) {
      console.error(ex);
      setIsProcessedSuccessfully(false);
      setIsSubmitting(false);
    }
    showProcessNotification.toggleOpen();
  };

  const onSubmitForReview = async (payload: SubmitForReviewPayload) => {
    setIsSubmitting(true);
    const token: string = await getAccessTokenSilently();
    try {
      await postSubmitForReview(token, appointment?._id, appointmentClinician?._id, payload);
      showSubmitNotification.toggleOpen();
      setIsSubmitSuccessfully(true);
      goToTacklitCalendar();
    } catch (ex) {
      console.error(ex);
      setIsSubmitSuccessfully(false);
      setIsSubmitting(false);
    }
    showProcessNotification.toggleOpen();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <TacklitSecureLogo />
          <div className={styles.rightContent}>
            {user && (
              <div className={styles.clinician}>
                <Avatar className={styles.practiceAvatar} src={user.picture} />
                <div className={styles.names}>
                  <div className={styles.clinicianName}>{user.name}</div>
                  <div className={styles.practiceName}>{user.practice?.name}</div>
                </div>
              </div>
            )}
            <Button onClick={handleBackToTacklit} className={styles.backToTacklitButton}>
              <ExitToAppIcon />
              <div className={styles.buttonLabel}>Back To Tacklit</div>
            </Button>
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <ProcessAppointmentForm
            appointment={appointment}
            isLoading={isListDataLoading}
            appointmentTypes={appointmentTypes || []}
            providers={providers || []}
            selectedPractitioner={selectedPractitioner}
            isSubmitting={isSubmitting}
            onProcess={onProcess}
            onSubmitForReview={onSubmitForReview}
          />
        </div>

        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </div>
      <Snackbar
        autoHideDuration={3000}
        open={showProcessNotification.open}
        handleClose={showProcessNotification.toggleOpen}
        headline={isProcessedSuccessfully ? "Success" : "Error"}
        message={
          isProcessedSuccessfully
            ? "Appointment processed successfully"
            : "Something went wrong while trying to process appointment"
        }
        variant={isProcessedSuccessfully ? "info" : "error"}
      />
      <Snackbar
        autoHideDuration={3000}
        open={showSubmitNotification.open}
        handleClose={showSubmitNotification.toggleOpen}
        headline={isSubmitSuccessfully ? "Success" : "Error"}
        message={
          isSubmitSuccessfully
            ? "Appointment submitted for review successfully"
            : "Something went wrong while trying to submit for review"
        }
        variant={isSubmitSuccessfully ? "info" : "error"}
      />
    </>
  );
};

export default ProcessAppointmentPage;
