import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  createStyles,
  makeStyles
} from "@material-ui/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import moment from "moment";
import { useMemo } from "react";
import {
  AppointmentSlots,
  AppointmentType,
  CLAIM_TYPE_LABELS,
  CODE_TYPE_BULK_BILL,
  CODE_TYPE_DVA,
  CODE_TYPE_REBATE,
  ClaimType,
  DeliveryType,
  FormError,
  Practitioner,
  ServiceDeliveredOptions,
  getInvoiceAmount
} from "./helpers";
import { ClinicianMedicareRole, MBS_CODE_ITEMS } from "./mbsItems";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: "#fff",
      border: "1px solid #dadbde",
      borderRadius: "4px",
      padding: "12px 20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
      gap: "16px"
    },
    button: {
      border: "1px solid #3F52FF",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
      color: "#3F52FF",
      padding: "12px"
    },
    serviceFeeCheckbox: { marginLeft: "32px" },
    select: {
      "&:before": {
        borderColor: "transparent"
      },
      "&:after": {
        borderColor: "transparent",
        borderBottomWidth: "1px"
      },
      "& .MuiSelect-select": {
        color: "#414449",
        fontSize: "1rem",
        padding: "4px 0"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent"
      },
      "&.error": {
        borderColor: "#d54141"
      },
      "& .MuiSelect-icon": {
        position: "relative"
      }
    },
    deleteButtonWrapper: {
      fontSize: "1rem",
      color: "#d54141",
      display: "flex",
      justifyContent: "flex-end",
      cursor: "pointer"
    },
    messageWrapper: {
      padding: "12px 24px",
      color: "#414449",
      fontSize: "0.625rem",
      fontWeight: 400,
      lineHeight: "0.938rem"
    },
    mbsSelectWrapper: {
      minWidth: "480px"
    },
    optionWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "12px",
      paddingRight: "8px"
    },
    tag: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "72px",
      backgroundColor: "#2a4670",
      borderRadius: "4px",
      color: "#f2f2f2",
      fontSize: "0.688rem",
      fontWeight: 400,
      lineHeight: "0.938rem",
      letterSpacing: "0.006em"
    },
    description: {
      color: "#333",
      fontSize: "0.688rem",
      fontWeight: 400,
      lineHeight: "0.938rem",
      letterSpacing: "0.006em",
      maxWidth: "360px",
      whiteSpace: "normal"
    },
    labelWrapper: {
      paddingRight: "8px"
    },
    codeTypeNote: {
      width: "237px",
      display: "flex",
      gap: "4px",
      fontSize: "12px",
      lineHeight: "18px",
      padding: "8px"
    },
    infoIcon: {
      fontSize: "20px"
    }
  })
);

interface AppointmentClaimDetailsProps {
  appointment: AppointmentSlots;
  selectedAppointmentType?: AppointmentType;
  selectedServiceDelivered?: ServiceDeliveredOptions;
  isPrepareClaimOpen: boolean;
  onSetIsPrepareClaimOpen: (isPrepareClaimOpen: boolean) => void;
  selectedPractitioner: Practitioner;
  selectedClaimType?: ClaimType;
  onSelectClaimType: (claimType?: ClaimType) => void;
  selectedMbsCode?: string;
  onSelectMbsCode: (mbsCode?: string) => void;
  selectedDeliveryType?: DeliveryType;
  useServiceFee?: boolean;
  onSetUseServiceFee: (value: boolean) => void;
  formError: FormError;
}

const AppointmentClaimDetails = ({
  appointment,
  selectedAppointmentType,
  selectedServiceDelivered,
  isPrepareClaimOpen,
  onSetIsPrepareClaimOpen,
  selectedPractitioner,
  selectedClaimType,
  onSelectClaimType,
  selectedMbsCode,
  onSelectMbsCode,
  selectedDeliveryType,
  useServiceFee,
  onSetUseServiceFee,
  formError
}: AppointmentClaimDetailsProps) => {
  const classes = useStyles();
  const mbsCodeOptions = useMemo(() => {
    return MBS_CODE_ITEMS[selectedPractitioner.medicare?.role || ClinicianMedicareRole.ClinicalPsychologists].map(
      ({ mbsCode, benefit, description }) => ({
        label: mbsCode,
        value: mbsCode,
        benefit,
        description
      })
    );
  }, [selectedPractitioner]);

  const codeTypeOptions = useMemo(() => {
    switch (selectedAppointmentType?.claimType) {
      case ClaimType.BULK_BILL:
        return [CODE_TYPE_BULK_BILL, CODE_TYPE_DVA];
      case ClaimType.REBATE:
        return [CODE_TYPE_REBATE];
      default:
        return [CODE_TYPE_BULK_BILL, CODE_TYPE_REBATE, CODE_TYPE_DVA];
    }
  }, [selectedAppointmentType]);

  const handleRemoveClaimDetails = () => {
    onSetIsPrepareClaimOpen(false);
    onSelectClaimType(undefined);
    onSelectMbsCode(undefined);
    onSetUseServiceFee(false);
  };

  const getMessage = (claimType?: string) => {
    switch (claimType) {
      case ClaimType.BULK_BILL:
        return "This will create a pending Bulk Bill claim. To be processed client medicare and referral data must be accurate and allow this service.";
      case ClaimType.REBATE:
        return "This will create a pending Rebate claim. To be processed the appointment fee must be paid in full and relevant client medicare and referral data must be accurate and allow this service.";
      case ClaimType.DVA:
        return "This will create a pending DVA claim.";
      default:
        return null;
    }
  };

  const selectedMbsCodeObj = mbsCodeOptions.find((code) => code.value === selectedMbsCode);
  const showCodeClaimTypeNote =
    selectedAppointmentType?.claimType &&
    [ClaimType.BULK_BILL, ClaimType.REBATE].includes(selectedAppointmentType.claimType);

  const disabledPrepareClaim =
    !selectedAppointmentType?.claimType ||
    ![ClaimType.BULK_BILL, ClaimType.REBATE, ClaimType.DVA].includes(selectedAppointmentType.claimType) ||
    selectedServiceDelivered !== ServiceDeliveredOptions.Attended;

  const invoiceFee = useMemo(() => {
    const sessionDuration =
      appointment.startDateTime && appointment.endDateTime
        ? moment(appointment.endDateTime).diff(moment(appointment.startDateTime), "minutes")
        : selectedAppointmentType?.duration.minutes || 0;
    return getInvoiceAmount(selectedMbsCode, selectedClaimType, sessionDuration, selectedDeliveryType) || 0;
  }, [
    selectedMbsCode,
    selectedClaimType,
    selectedDeliveryType,
    selectedAppointmentType,
    appointment.endDateTime,
    appointment.startDateTime
  ]);

  return !disabledPrepareClaim && isPrepareClaimOpen ? (
    <>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div>
            <FormControl>
              <Select
                displayEmpty
                onChange={(e) => onSelectClaimType(e.target.value as ClaimType)}
                value={selectedClaimType || ""}
                variant="standard"
                className={classes.select}
                error={formError.selectedClaimType}
                renderValue={(value) =>
                  !value ? (
                    <div className={classes.labelWrapper}>Code Type</div>
                  ) : (
                    <div className={classes.labelWrapper}>
                      {selectedClaimType ? CLAIM_TYPE_LABELS[selectedClaimType] : ""}
                    </div>
                  )
                }
              >
                {codeTypeOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
                {showCodeClaimTypeNote && (
                  <div className={classes.codeTypeNote}>
                    <InfoOutlinedIcon className={classes.infoIcon} />
                    This appointment only supports limited code types. To use another code type please change the
                    appointment type above.
                  </div>
                )}
              </Select>
            </FormControl>
          </div>
          <Divider orientation="vertical" flexItem={true} />
          <div>
            <FormControl>
              <Select
                displayEmpty
                onChange={(e) => onSelectMbsCode(e.target.value as string)}
                value={selectedMbsCode || ""}
                variant="standard"
                className={classes.select}
                error={formError.selectedMbsCode}
                renderValue={(value) =>
                  value && selectedMbsCodeObj ? (
                    <div className={classes.optionWrapper}>
                      <div className={classes.tag}>{selectedMbsCodeObj.label}</div>
                    </div>
                  ) : (
                    <div className={classes.labelWrapper}>Code</div>
                  )
                }
              >
                {mbsCodeOptions.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    <div className={classes.optionWrapper}>
                      <div className={classes.tag}>{option.label}</div>
                      <div className={classes.description}>{option.description}</div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {selectedMbsCode && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.serviceFeeCheckbox}
                    checked={useServiceFee}
                    onChange={(e) => onSetUseServiceFee(e.target.checked)}
                    name="useServiceFee"
                    color="primary"
                  />
                }
                label={`Use the Medicare fee schedule price of $${invoiceFee.toFixed(2)}`}
              />
            </div>
          )}
        </div>
        <div className={classes.deleteButtonWrapper} onClick={handleRemoveClaimDetails}>
          <DeleteOutlineIcon fontSize="inherit" />
        </div>
      </div>
      {selectedClaimType && <div className={classes.messageWrapper}>{getMessage(selectedClaimType)}</div>}
    </>
  ) : (
    <Button
      disabled={disabledPrepareClaim}
      variant="outlined"
      className={classes.button}
      startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
      onClick={() => onSetIsPrepareClaimOpen(!isPrepareClaimOpen)}
    >
      Prepare Claim
    </Button>
  );
};

export default AppointmentClaimDetails;
