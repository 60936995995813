import { Button, CircularProgress, makeStyles } from "@material-ui/core";

import CallEndIcon from "@mui/icons-material/CallEnd";

const useStyles = makeStyles(() => ({
  connectingCall: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 40
  },
  hangupIcon: {
    marginRight: 5
  }
}));

interface ConnectingCallProps {
  handleHangup: () => void;
  displayName: string;
}

const ConnectingCall = ({ handleHangup, displayName }: ConnectingCallProps) => {
  const styles = useStyles();

  return (
    <>
      <p>Calling {displayName}...</p>
      <div className={styles.connectingCall}>
        <CircularProgress size={30} />
        <Button variant="contained" onClick={handleHangup} color="secondary">
          <CallEndIcon className={styles.hangupIcon} />
          Hang up
        </Button>
      </div>
    </>
  );
};

export default ConnectingCall;
