import LoginWrapper from "../LoginWrapper/LoginWrapper";
import BaseVideoApp from "./BaseVideoApp";

const HostVideoApp = () => {
  return (
    <LoginWrapper>
      <BaseVideoApp />
    </LoginWrapper>
  );
};

export default HostVideoApp;
