import { Button, FormControl, MenuItem, Typography, Select, makeStyles } from "@material-ui/core";
import CloseIcon from "../../../../icons/CloseIcon";
import { useVideoContext } from "../../../VideoProvider";
import { LocalAudioTrack } from "twilio-video";
import useMediaStreamTrack from "../../../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import { SELECTED_AUDIO_INPUT_KEY } from "../../../../constants";
import AudioLevelIndicatorBar from "../../../AudioLevelIndicatorBar/AudioLevelIndicatorBar";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "300px",
    maxWidth: "300px",
    minWidth: "240px",
    padding: "8px 12px",
    background: "#FFF",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.10)",
    overflow: "hidden",
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    top: "8px",
    right: "12px",
    minWidth: 0,
    padding: 0
  },
  overlayTitle: {
    maxWidth: "calc(100% - 30px)",
    marginBottom: "8px",
    color: "#414449",
    fontSize: "12px",
    fontWeight: 600
  },
  selectContainer: {
    width: "100%",
    marginBottom: "12px"
  }
}));

interface MicrophoneSettingsProps {
  onClose?: () => void;
}

const MicrophoneSettings = ({ onClose }: MicrophoneSettingsProps) => {
  const classes = useStyles();
  const { audioInputDevices, localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find((track) => track.kind === "audio") as LocalAudioTrack;
  const srcMediaStreamTrack = localAudioTrack?.noiseCancellation?.sourceTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId =
    srcMediaStreamTrack?.getSettings().deviceId || mediaStreamTrack?.getSettings().deviceId;

  const replaceTrack = (newDeviceId: string) => {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  };

  return (
    <div className={classes.container}>
      <Button className={classes.closeButton} variant="text" onClick={onClose}>
        <CloseIcon size={16} color="#414449" stroke strokeWidth="2" />
      </Button>
      <div className={classes.overlayTitle}>Microphone options</div>
      <div className={classes.selectContainer}>
        {audioInputDevices.length > 0 ? (
          <FormControl fullWidth>
            <Select
              onChange={(e) => replaceTrack(e.target.value as string)}
              onMouseDown={(e) => e.stopPropagation()}
              value={localAudioInputDeviceId || ""}
              variant="outlined"
            >
              {audioInputDevices.map((device) => (
                <MenuItem value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Typography>{"No Local Audio"}</Typography>
        )}
      </div>
      <AudioLevelIndicatorBar audioTrack={localAudioTrack} />
    </div>
  );
};

export default MicrophoneSettings;
