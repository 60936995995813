import { ParticipantType } from "../../services/schedService";
import {
  DVA_PHONE_CALL_MORE_THAN_20,
  DVA_PHONE_CALL_MORE_THAN_50,
  DVA_VIDEO_CALL_MORE_THAN_20,
  DVA_VIDEO_CALL_MORE_THAN_50
} from "./dvaItems/dvaItems";
import { ClinicianMedicareRole, MBS_CODE_FULL_LIST, MBS_CODE_ITEMS } from "./mbsItems";
import { MedicareItemDuration, MedicareItemFormat, MedicareItemMode } from "./mbsItems/interfaces";

export enum DeliveryType {
  BeSpoke = "bespoke",
  FaceToFace = "faceToFace",
  VideoCall = "videoCall",

  // telehealth and regular phone call use this
  PhoneCall = "phoneCall",
  // only used for telehealth accounts to not generate telehealth voice link
  PhoneCallDialClient = "phoneCallDialClient",
  Other = "other"
}

export const DELIVERY_TYPE_LABELS: Record<DeliveryType, string> = {
  bespoke: "Bespoke",
  faceToFace: "Face to face",
  phoneCall: "Phone call",
  phoneCallDialClient: "Phone call (dial client)",
  videoCall: "Video call",
  other: "Other"
};

export const CLAIM_TYPE_LABELS: Record<ClaimType, string> = {
  rebate: "Medicare - Rebate",
  bulkBill: "Medicare - BulkBill",
  dva: "DVA",
  outOfPocket: "Out Of Pocket"
};

export interface RequestedChanges {
  appointmentTypeId?: string;
  deliveryType?: DeliveryType;
  providerId?: string;
  funder?: ClaimType;
  mbsCode?: string;
  clinicianId?: string;
  createdAt?: Date;
  waiveCancellationFee?: boolean;
  reviewNote: string;
}

export interface AppointmentSlots {
  _id: string;
  accountId: string;
  clinicianId: string;
  sessionTypeId: string;
  sessionTypeName: string;
  clientRecord: {
    _id: string;
    clientProfiles: {
      _id: string;
      isPrimaryContact: boolean;
      avatar?: string;
      initials: string;
      name: string;
    }[];
  };
  deliveryType: DeliveryType;
  participantType: ParticipantType;
  startTime: string;
  endTime: string;
  startDateTime?: string;
  endDateTime?: string;
  claimType?: ClaimType;
  isProcessed?: boolean;
  requestedChanges?: RequestedChanges;
}

interface AppointmentTypeDuration {
  minutes: number;
  editable: boolean;
}

export interface AppointmentType {
  _id: string;
  name: string;
  claimType: ClaimType;
  deliveryOptions: DeliveryType[];
  duration: AppointmentTypeDuration;
  isActive: boolean;
}

export interface Provider {
  accountId: string;
  clinicianId: string;
  locationMinorId: string;
  name: string;
  providerNumber: string;
  active: boolean;
  default: boolean;
}

export interface FormError {
  selectedAppointmentType?: boolean;
  selectedDeliveryType?: boolean;
  selectedServiceDelivered?: boolean;
  selectedClaimType?: boolean;
  selectedMbsCode?: boolean;
  reviewNote?: boolean;
}

export interface Practitioner {
  _id: string;
  name: string;
  accountId: string;
  medicare?: {
    role: ClinicianMedicareRole;
  };
}

export enum ServiceDeliveredOptions {
  Attended = "attended",
  ClientDidNotAttend = "clientDidNotAttend",
  CancelShortNotice = "cancelShortNotice"
}

export enum ClaimType {
  DVA = "dva",
  BULK_BILL = "bulkBill",
  REBATE = "rebate",
  OUT_OF_POCKET = "outOfPocket"
}

export const CODE_TYPE_BULK_BILL = { label: "Medicare - BulkBill", value: ClaimType.BULK_BILL };
export const CODE_TYPE_REBATE = { label: "Medicare - Rebate", value: ClaimType.REBATE };
export const CODE_TYPE_DVA = { label: "DVA", value: ClaimType.DVA };

export const mbsItemLookup = ({
  deliveryType,
  duration,
  clinicianRole
}: {
  deliveryType: DeliveryType;
  duration: number;
  clinicianRole?: ClinicianMedicareRole;
}) => {
  const mbsItems = MBS_CODE_ITEMS[clinicianRole || ClinicianMedicareRole.ClinicalPsychologists];
  const serviceDurationType = [
    ...(duration > 20 ? [MedicareItemDuration.OneToOneMoreThan20] : [MedicareItemDuration.OneToOneLessThan20]),
    ...(duration > 30 ? [MedicareItemDuration.OneToOneMoreThan30] : []),
    ...(duration < 40 ? [MedicareItemDuration.OneToOneLessThan40] : [MedicareItemDuration.OneToOneMoreThan40]),
    ...(duration > 45 ? [MedicareItemDuration.OneToOneMoreThan45] : []),
    ...(duration < 50 ? [MedicareItemDuration.OneToOneLessThan50] : [MedicareItemDuration.OneToOneMoreThan50])
  ];
  const serviceMode =
    deliveryType === DeliveryType.FaceToFace
      ? [MedicareItemMode.FaceToFace]
      : deliveryType === DeliveryType.VideoCall
      ? [MedicareItemMode.VideoCall, MedicareItemMode.Telehealth]
      : [DeliveryType.PhoneCall, DeliveryType.PhoneCallDialClient].includes(deliveryType)
      ? [MedicareItemMode.PhoneCall, MedicareItemMode.Telehealth]
      : [
          MedicareItemMode.Any,
          MedicareItemMode.FaceToFace,
          MedicareItemMode.PhoneCall,
          MedicareItemMode.VideoCall,
          MedicareItemMode.Telehealth
        ];

  return (
    mbsItems.find(
      ({ duration, format, mode }) =>
        format === MedicareItemFormat.OneToOne && serviceDurationType.includes(duration) && serviceMode.includes(mode)
    ) ||
    mbsItems.find(
      ({ duration, format, mode }) =>
        (format === MedicareItemFormat.OneToOne &&
          serviceDurationType.includes(duration) &&
          mode === MedicareItemMode.Any) ||
        (duration === MedicareItemDuration.Any && serviceMode.includes(mode))
    )
  );
};

export const needResetPrepareClaim = ({
  selectedAppointmentType
}: {
  selectedAppointmentType: AppointmentType | undefined;
}) => {
  return (
    !selectedAppointmentType?.claimType ||
    (selectedAppointmentType.claimType &&
      selectedAppointmentType.claimType !== ClaimType.BULK_BILL &&
      selectedAppointmentType.claimType !== ClaimType.REBATE)
  );
};

const getDVAFee = (duration: number, deliveryMode: string) => {
  if (duration >= 50) {
    return deliveryMode === DeliveryType.PhoneCall
      ? DVA_PHONE_CALL_MORE_THAN_50.benefit
      : DVA_VIDEO_CALL_MORE_THAN_50.benefit;
  }
  return deliveryMode === DeliveryType.PhoneCall
    ? DVA_PHONE_CALL_MORE_THAN_20.benefit
    : DVA_VIDEO_CALL_MORE_THAN_20.benefit;
};

export const getInvoiceAmount = (
  selectedMbsCode: string | undefined,
  selectedClaimType: ClaimType | undefined,
  duration: number,
  deliveryMode: DeliveryType | undefined
) => {
  if (
    selectedClaimType === ClaimType.DVA &&
    duration >= 20 &&
    deliveryMode &&
    [DeliveryType.PhoneCall, DeliveryType.VideoCall].includes(deliveryMode)
  ) {
    return getDVAFee(duration, deliveryMode);
  }

  if (selectedMbsCode) {
    return MBS_CODE_FULL_LIST.find(({ mbsCode }) => mbsCode === selectedMbsCode)?.benefit;
  }
};
