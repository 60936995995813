import { createStyles, makeStyles, MuiThemeProvider, Theme } from "@material-ui/core";
import { AppStateProvider } from "./components/AppStateProvider/AppStateProvider";
import Header from "./components/Header/Header";
import MainContent from "./components/MainContent/MainContent";
import theme from "./theme";
import TacklitLogo from "../../assets/icons/tacklit-logo-white.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appContainer: {
      position: "relative",
      width: `calc(100% - ${theme.brandSidebarWidth}px)`,
      height: "100%",
      background: theme.backgroundColor,
      overflow: "hidden",
      [theme.breakpoints.between("md", 1280)]: {
        width: `calc(100% - ${theme.tabletBrandSidebarWidth}px)`
      },
      [theme.breakpoints.down("sm") + theme.includeLandscapeMd]: {
        width: "100%",
        overflowY: "auto"
      }
    },
    brandSidebar: {
      background: "#2a4670",
      position: "fixed",
      top: 0,
      bottom: 0,
      right: 0,
      left: `calc(100% - ${theme.brandSidebarWidth}px)`,
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      "& img": {
        position: "absolute",
        bottom: 40,
        width: "50%",
        opacity: 0.5
      },
      [theme.breakpoints.between("md", 1280)]: {
        left: `calc(100% - ${theme.tabletBrandSidebarWidth}px)`
      },
      [theme.breakpoints.down("sm") + theme.includeLandscapeMd]: {
        display: "none"
      }
    }
  })
);

const VideoTest = () => {
  const classes = useStyles();

  return (
    <AppStateProvider>
      <div className={classes.appContainer}>
        <Header />
        <MainContent />
        <div className={classes.brandSidebar}>
          <img src={TacklitLogo} alt="tacklit-logo" />
        </div>
      </div>
    </AppStateProvider>
  );
};

// Based off of https://github.com/twilio/twilio-video-diagnostics-react-app
const ThemedVideoTest = () => (
  <MuiThemeProvider theme={theme}>
    <VideoTest />
  </MuiThemeProvider>
);

export default ThemedVideoTest;
