import { Popper, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Switch from "../../Switch/Switch";
import { useVideoContext } from "../../VideoProvider";
import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import { useEffect, useRef, useState } from "react";
import Hidden from "../../Hidden/Hidden";
import MicrophoneSettings from "./MicrophoneSettings/MicrophoneSettings";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#ECEDEF",
    borderRadius: "4px"
  },
  control: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "#FFF",
    borderRadius: "4px",
    zIndex: 1,
    position: "relative"
  },
  settings: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    marginTop: "-4px",
    padding: "6px 0 2px 8px",
    fontSize: "12px",
    color: "#3F52FF",
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)",
    "&:not(:disabled)": {
      "&:hover, &:active": {
        backgroundColor: "#D9DCFF"
      }
    },
    "&:disabled": {
      color: "#BDBDBD",
      cursor: "default"
    }
  },
  overlay: {
    zIndex: 1
  }
}));

interface AudioInputSwitchProps {
  className?: string;
  disabled?: boolean;
}

const AudioInputSwitch = ({ className, disabled }: AudioInputSwitchProps) => {
  const classes = useStyles();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();

  const microphoneSettingsAnchorRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const [isMicrophoneSettingsVisible, setIsMicrophoneSettingsVisible] = useState(false);

  const handleClick = (e: any) => {
    if (
      !popperRef.current?.contains(e.target) &&
      e.target?.attributes?.["aria-describedby"]?.value !== "audio-input-switch-microphone-settings-button"
    ) {
      setIsMicrophoneSettingsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const isDisabled = !localTracks.some((track) => track.kind === "audio") || disabled;

  return (
    <div className={clsx(classes.container, className)} ref={microphoneSettingsAnchorRef}>
      <div className={classes.control}>
        <div>Microphone</div>
        <Switch
          checked={isAudioEnabled}
          disabled={isDisabled}
          onClick={toggleAudioEnabled}
          onMouseDown={(e) => e.stopPropagation()}
        />
      </div>
      <button
        className={classes.settings}
        aria-describedby="audio-input-switch-microphone-settings-button"
        type="button"
        disabled={isDisabled}
        onClick={() => setIsMicrophoneSettingsVisible(!isMicrophoneSettingsVisible)}
      >
        Microphone settings
      </button>
      {microphoneSettingsAnchorRef.current && (
        <>
          <Hidden smDown>
            <Popper
              id="audio-input-switch-microphone-settings-button"
              className={classes.overlay}
              ref={popperRef}
              anchorEl={microphoneSettingsAnchorRef.current}
              placement="right-start"
              open={isMicrophoneSettingsVisible}
              modifiers={{
                offset: {
                  enabled: true,
                  offset: "0,12"
                }
              }}
            >
              <MicrophoneSettings onClose={() => setIsMicrophoneSettingsVisible(false)} />
            </Popper>
          </Hidden>
          <Hidden mdUp>
            <Popper
              id="audio-input-switch-microphone-settings-button"
              className={classes.overlay}
              ref={popperRef}
              anchorEl={microphoneSettingsAnchorRef.current}
              placement="bottom"
              open={isMicrophoneSettingsVisible}
              modifiers={{
                flip: {
                  enabled: false
                }
              }}
            >
              <MicrophoneSettings onClose={() => setIsMicrophoneSettingsVisible(false)} />
            </Popper>
          </Hidden>
        </>
      )}
    </div>
  );
};

export default AudioInputSwitch;
