import { useEffect, useRef, useState } from "react";
import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from "twilio-video";
import useIsTrackEnabled from "../../hooks/useIsTrackEnabled/useIsTrackEnabled";
import useMediaStreamTrack from "../../hooks/useMediaStreamTrack/useMediaStreamTrack";
import { initializeAnalyser } from "../AudioLevelIndicator/AudioLevelIndicator";
import { interval } from "d3-timer";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "2px"
  },
  audioBit: {
    flex: "1 0 10%",
    height: "10px",
    backgroundColor: "#ECEDEF"
  }
}));

interface AudioLevelIndicatorBarProps {
  audioTrack?: AudioTrack;
}

const isIOS = /iPhone|iPad/.test(navigator.userAgent);

/**
 * Built with reference to https://github.com/tacklit/tele-ui/blob/acf05168b064dec8e6ab07d1b6e089199b9a1a62/src/components/AudioLevelIndicator/AudioLevelIndicator.tsx
 */
const AudioLevelIndicatorBar = ({ audioTrack }: AudioLevelIndicatorBarProps) => {
  const classes = useStyles();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);

  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isTrackEnabled) {
      let newMediaStream = new MediaStream([isIOS ? mediaStreamTrack.clone() : mediaStreamTrack]);

      const stopAllMediaStreamTracks = () => {
        if (isIOS) {
          newMediaStream.getTracks().forEach((track) => track.stop());
        }
        newMediaStream.dispatchEvent(new Event("cleanup"));
      };
      audioTrack.on("stopped", stopAllMediaStreamTracks);

      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([isIOS ? mediaStreamTrack.clone() : mediaStreamTrack]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));

      window.addEventListener("focus", reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener("focus", reinitializeAnalyser);
        audioTrack.off("stopped", stopAllMediaStreamTracks);
      };
    }
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);

  useEffect(() => {
    const containerElement = containerRef.current;

    if (isTrackEnabled && containerElement && analyser) {
      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const timer = interval(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;

        const length = sampleArray.length;
        for (let i = 0; i < length; i++) {
          values += sampleArray[i];
        }

        // value can be 0-255, dividing by 223 because anything above that is shouting
        const volume = Math.max(0, values / length) / 223;

        const allBits = [...containerElement.querySelectorAll(`.${classes.audioBit}`)] as HTMLDivElement[];
        const bitsToColor = allBits.slice(0, Math.round(volume * allBits.length * 1.2));

        for (const bit of allBits) {
          bit.style.backgroundColor = "#ECEDEF";
        }
        for (const bit of bitsToColor) {
          bit.style.backgroundColor = "#4BC27D";
        }
      }, 100);

      return () => {
        const allBits = [...containerElement.querySelectorAll(`.${classes.audioBit}`)] as HTMLDivElement[];
        for (const bit of allBits) {
          bit.style.backgroundColor = "#ECEDEF";
        }
        timer.stop();
      };
    }
  }, [isTrackEnabled, analyser, classes.audioBit]);

  return (
    <div className={classes.container} ref={containerRef}>
      <div className={classes.audioBit} />
      <div className={classes.audioBit} />
      <div className={classes.audioBit} />
      <div className={classes.audioBit} />
      <div className={classes.audioBit} />
      <div className={classes.audioBit} />
      <div className={classes.audioBit} />
      <div className={classes.audioBit} />
    </div>
  );
};

export default AudioLevelIndicatorBar;
