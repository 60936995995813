import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from "@material-ui/core";
import { useState } from "react";

interface InitiateDialogProps {
  initializeDevice: () => void;
}
const InitiateDialog = ({ initializeDevice }: InitiateDialogProps) => {
  const [open, setOpen] = useState(true);

  const init = () => {
    initializeDevice();
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs">
      <DialogTitle>Start Up The Device</DialogTitle>
      <DialogContent>
        <DialogContentText>Start the device before initiating call</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => init()} color="primary" variant="contained">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InitiateDialog;
