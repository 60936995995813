import { makeStyles, Switch as MuiSwitch } from "@material-ui/core";
import { MouseEventHandler } from "react";
import SmallCheckIcon from "../../icons/SmallCheckIcon";
import CloseIcon from "../../icons/CloseIcon";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  closeIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px",
    backgroundColor: "#D54141",
    borderRadius: "50%",
    height: "24px"
  },
  checkIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1px",
    backgroundColor: "#25BE69",
    borderRadius: "50%",
    height: "24px"
  },
  disabled: {
    backgroundColor: "#bdbdbd"
  }
}));

interface SwitchProps {
  checked?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
}

const Switch = ({ checked, disabled, onClick, onMouseDown }: SwitchProps) => {
  const classes = useStyles();

  return (
    <MuiSwitch
      checked={checked}
      icon={
        <div className={clsx(classes.closeIconContainer, { [classes.disabled]: disabled })}>
          <CloseIcon size={12} color="#FFF" stroke strokeWidth="4" />
        </div>
      }
      checkedIcon={
        <div className={clsx(classes.checkIconContainer, { [classes.disabled]: disabled })}>
          <SmallCheckIcon size={22} backgroundColor="none" color="#FFF" stroke />
        </div>
      }
      disableRipple
      disabled={disabled}
      onClick={onClick}
      onMouseDown={onMouseDown}
    />
  );
};

export default Switch;
