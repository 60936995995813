import { Room, TwilioError } from "twilio-video";
import { useEffect } from "react";

import { Callback } from "../../../types";
import { AccountSettings } from "../../../services/schedService";
import { StateContextType } from "../../../state";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

export default function useHandleRoomDisconnection({
  room,
  accountSettings,
  user,
  isSharingScreen,
  removeLocalAudioTrack,
  removeLocalVideoTrack,
  toggleScreenShare,
  callbacks: { onError, onSuccess }
}: {
  room: Room | null;
  accountSettings?: AccountSettings;
  user: StateContextType["user"];
  isSharingScreen: boolean;
  removeLocalAudioTrack: () => void;
  removeLocalVideoTrack: () => void;
  toggleScreenShare: () => void;
  callbacks: {
    onError: Callback;
    onSuccess: Callback;
  };
}) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (room) {
      const onDisconnected = (_: Room, error: TwilioError) => {
        if (error) {
          // use onSuccess if room ended by host
          if (error.code === 53118) {
            const parsedSearch = queryString.parse(location.search);

            if (accountSettings?.postSessionFormId && !user?.isClinician && parsedSearch.skipForms !== "1") {
              navigate(`/room/${room.name}/survey/${accountSettings.postSessionFormId}`);
            } else {
              onSuccess({
                name: "Room Ended",
                message: "Room ended by host"
              });
            }
          } else {
            onError(error);
          }
        }

        removeLocalAudioTrack();
        removeLocalVideoTrack();
        if (isSharingScreen) {
          toggleScreenShare();
        }
      };

      room.on("disconnected", onDisconnected);
      return () => {
        room.off("disconnected", onDisconnected);
      };
    }
  }, [
    accountSettings?.postSessionFormId,
    isSharingScreen,
    location.search,
    navigate,
    onError,
    onSuccess,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    room,
    toggleScreenShare,
    user?.isClinician
  ]);
}
